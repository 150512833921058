import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import GeneralInformationTab from './GeneralInformation/GeneralInformationTab';
import PricingAndFeeTab from './PricingAndFee/PricingAndFeeTab';
import RelationAndPerformaceTab from './RelationAndPerformace/RelationAndPerformaceTab';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Routes } from '../../config';
import { usePrompt } from '../../hooks/useConfirmExit';

const RequestInformation: React.FunctionComponent = () => {

    let [showDialog, setShowDialog] = React.useState<boolean>(false);
    usePrompt("Are you sure you want to leave and discard unsaved changes?", showDialog);

    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if (!showDialog)
            setValue(newValue);
        else {
            if (window.confirm("Are you sure you want to leave and discard unsaved changes?")) {
                setValue(newValue);
            }
        }
    };

    const navigate = useNavigate();
    const onBack = React.useCallback((): void => {
        toast.dismiss();
        const summaryRoute = Routes.find((x) => x.name === 'Summary')!;
        navigate(summaryRoute.path);
    }, [navigate]);

    return (<div className="section">
        <div className="container is-fluid">
            <h3 className="title is-3">Request Information</h3>
            <div className="box container-box">
                <div className="columns form-columns">
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab icon={<InfoOutlinedIcon />} label="Loan General Information" value="1" />
                                    <Tab icon={<MonetizationOnOutlinedIcon />} label="Pricing and Fees" value="2" />
                                    <Tab icon={<AssessmentOutlinedIcon />} label="Relationships/Performance" value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <GeneralInformationTab onBack={onBack} setShowDialog={setShowDialog} />
                            </TabPanel>
                            <TabPanel value="2">
                                <PricingAndFeeTab onBack={onBack} setShowDialog={setShowDialog} />
                            </TabPanel>
                            <TabPanel value="3">
                                <RelationAndPerformaceTab onBack={onBack} setShowDialog={setShowDialog} />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </div>
            </div>
        </div>
    </div>
    );
};

export default RequestInformation;