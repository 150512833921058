import * as React from 'react';
import * as Yup from 'yup';
import { DataGridPro, GridActionsCellItem, GridRowModes, type GridCellParams, type GridColumnVisibilityModel, type GridEventListener, type GridRowId, type GridRowModel, type GridRowModesModel, type MuiEvent } from '@mui/x-data-grid-pro';
import { Alert, Snackbar, type AlertProps } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { saveBulkEditRequestAsync, type BulkEditPageModel } from '../../store/bulkEditSlice';
import { BulkEditGridColumns } from './BulkEditGridColumns';
import BulkEditGridToolbar from './BulkEditGridToolbar';
import { useAppDispatch } from '../../store';
import type { LookupDataState } from '../../store/adminSlice';
import { BulkEditSchema } from './BulkEditSchema';

interface BulkEditGridProps {
    requests: BulkEditPageModel[],
    lookup: LookupDataState
}
const BulkEditGrid = React.memo<BulkEditGridProps>(({ requests, lookup }) => {
    const dispatch = useAppDispatch();
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [page, setPage] = React.useState<number>(0);
    const [rows, setRows] = React.useState<BulkEditPageModel[]>(requests);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>({
            requestId: false,
            loanNumber: true,
            borrowerName: true,
            cisnumber: true,
            clientName: true,
            clientRelationshipStartDate: true,
            borrowerType: true,
            productType: true,
            status: true,
            relationshipManagerName: true,
            portfolioManager: true,
            portfolioAnalyst: true,
            unit: true,
            actions: true,
        });

    const [snackbar, setSnackbar] = React.useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    React.useEffect(() => {
        setRows(requests);
    }, [requests]);

    const handleCellDoubleClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
        event.defaultMuiPrevented = true;
        setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
    };
    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow = rows.find((row) => row.id == id);
        if (editedRow?.id == 0) {
            setRows(rows.filter((row) => row.id != id));
        }
    };

    const ValidateRowData = (row: BulkEditPageModel): string => {
        try {
            Yup.object().shape(BulkEditSchema).validateSync(row, { abortEarly: false });
        } catch ({ errors }) {
            var err = JSON.stringify(errors, null, 2);
            //console.log(err);
            return err;
        }
        return '';
    };

    const UpdateRow = React.useCallback(
        (newRow: GridRowModel) =>
            new Promise<Partial<BulkEditPageModel>>(async (resolve, reject) => {
                //console.log(newRow);
                var data: BulkEditPageModel = {
                    id: newRow.id,
                    requestId: newRow.requestId,
                    loanNumber: newRow.loanNumber,
                    borrowerName: newRow.borrowerName,
                    cisnumber: newRow.cisnumber,
                    clientName: newRow.clientName,
                    clientRelationshipStartDate: newRow.clientRelationshipStartDate,
                    borrowerType: newRow.borrowerType,
                    productType: newRow.productType,
                    status: newRow.status,
                    portfolioManager: newRow.portfolioManager,
                    portfolioAnalyst: newRow.portfolioAnalyst,
                }
                var errorMsg = ValidateRowData(data);
                if (errorMsg != '') {
                    reject(new Error(errorMsg));
                } else {
                    const response = await dispatch(saveBulkEditRequestAsync(data));
                    if (response.payload === true)
                        resolve(newRow);
                    else
                        reject(new Error("Error while saving data: " + response.payload));
                }
            }),
        [dispatch]
    );

    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel) => {
            const response = await UpdateRow(newRow);
            setSnackbar({ children: 'Record updated successfully.', severity: 'success' });
            return response;
        },
        [UpdateRow]);

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);
    const getActions = ({ id }: any) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
            return [
                <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                    icon={<CancelIcon />}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                />,
            ];
        }

        return [
            <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
            />
        ];
    };

    return (
        <div style={{ height: 720, width: '100%' }}>
            <DataGridPro
                sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                }}
                disableColumnMenu={true}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                pagination
                columns={BulkEditGridColumns({ lookup, getActions })}
                rows={rows}
                editMode="row"
                getRowId={(row) => row.id}
                components={{ Toolbar: BulkEditGridToolbar }}
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                experimentalFeatures={{ newEditingApi: true }}
                onCellDoubleClick={handleCellDoubleClick}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </div>
    );
});

export default BulkEditGrid;