import { BaseService } from './base.service';
import type { ConfirmationPageModel, ReassignmentPageModel } from '../store/reassignmentSlice';

/**
 * Reassignment API abstraction layer communication via Axios (typescript singleton pattern)
 */
class ReassignmentService extends BaseService {
    private static _reassignmentService: ReassignmentService;
    private static _controller: string = 'Reassignment';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): ReassignmentService {
        return this._reassignmentService || (this._reassignmentService = new this(this._controller));
    }

    public async getRequestsAsync(): Promise<ReassignmentPageModel[]> {
        const url = `GetReassignmentPageRequests`;
        const { data } = await this.$http.get<ReassignmentPageModel[]>(url);
        return data;
    }

    public async saveReassignmentsAsync(reassignmentRequests: ConfirmationPageModel[]): Promise<boolean> {
        const { data } = await this.$http.post<boolean>('SaveReassignments', { reassignmentRequests });
        return data;
    }
}

export const ReassignmentApi = ReassignmentService.Instance;
