import * as React from 'react';
import { type LookupInfo } from '../../store/adminSlice';
import LookupGrid from './LookupGrid';

type LookupTableProps = Pick<LookupInfo, 'lookupType' | 'lookups'>;

const AccordianPanel = React.memo<LookupTableProps>(({ lookupType, lookups }) => {
    return (
        <LookupGrid lookupType={lookupType} lookups={lookups} />
    );
});
export default AccordianPanel;
