import { BaseService } from './base.service';
import type { LookupData, LookupTypeEnum, UserLookupData } from '../store/adminSlice';

/**
 * Admin API abstraction layer communication via Axios (typescript singleton pattern)
 */
class AdminService extends BaseService {
    private static _adminService: AdminService;
    private static _controller: string = 'Admin';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): AdminService {
        return this._adminService || (this._adminService = new this(this._controller));
    }

    public async getLookupsAsync(lookupType: LookupTypeEnum): Promise<LookupData[]> {
        const url = `GetLookupData?lookupType=${lookupType}`;
        const { data } = await this.$http.get<LookupData[]>(url);
        return data;
    }

    public async getUserLookupsAsync(lookupType: LookupTypeEnum): Promise<UserLookupData[]> {
        const url = `GetUserLookupData?lookupType=${lookupType}`;
        const { data } = await this.$http.get<UserLookupData[]>(url);
        return data;
    }

    public async putLookupsAsync(lookupType: LookupTypeEnum, lookup: LookupData): Promise<any> {
        const { data } = await this.$http.put<LookupData>('UpdateLookupData', { lookupType, lookup });
        return data;
    }

    public async postLookupsAsync(lookupType: LookupTypeEnum, lookup: LookupData): Promise<any> {
        const { data } = await this.$http.post<LookupData>('AddLookupData', { lookupType, lookup });
        return data;
    }

    public async deleteLookupsAsync(lookupType: LookupTypeEnum, key: number): Promise<any> {
        const url = `DeleteLookupData?lookupType=${lookupType}&key=${key}`;
        const { data } = await this.$http.delete<boolean[]>(url);
        return data;
    }
}

export const AdminApi = AdminService.Instance;
