import * as Yup from 'yup';

export const BulkEditSchema = {
    clientName: Yup.object().required("'Client Name' is a required field").nullable(),
    clientRelationshipStartDate: Yup.date().required("'Client Relationship Start Date' is a required field").typeError('Date must be in MM/DD/YYYY format').nullable(),
    borrowerType: Yup.object().required("'Borrower Type' is a required field").nullable(),
    productType: Yup.object().required("'Product Type' is a required field").nullable(),
    portfolioManager: Yup.object().required("'Portfolio Manager' is a required field").nullable(),
    portfolioAnalyst: Yup.object().required("'Portfolio Analyst' is a required field").nullable(),
};
