import authReducer from './authSlice';
import lookupReducer from './adminSlice';
import privateEquityRequestReducer from './privateEquityRequestSlice';
import summaryReducer from './summarySlice';
import reassignmentReducer from './reassignmentSlice';
import bulkEditReducer from './bulkEditSlice';
import paidOffReducer from './paidOffSlice';
import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        lookup: lookupReducer,
        privateEquityRequest: privateEquityRequestReducer,
        summary: summaryReducer,
        reassignment: reassignmentReducer,
        bulkEdit: bulkEditReducer,
        paidOff: paidOffReducer,
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {auth: AuthState, form: FormState, weather: WeatherState}
export type AppDispatch = typeof store.dispatch;