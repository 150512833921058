import { ReassignmentApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

export type ReassignmentPageModel = Readonly<{
    id: number,
    requestId: number;
    loanNumber: string;
    borrowerName: string;
    cisnumber: string;
    clientName: string;
    clientRelationshipStartDate: Date | undefined;
    borrowerType: string;
    productType: string;
    status: string;
    portfolioManagerName: string;
    portfolioManagerEmail: string;
    portfolioAnalystName: string;
    portfolioAnalystEmail: string;
}>;

export interface ConfirmationPageModel extends ReassignmentPageModel {
    portfolioManagerNameAfter: string,
    portfolioManagerEmailAfter: string,
    portfolioAnalystNameAfter: string
    portfolioAnalystEmailAfter: string
};

export type ReassignmentPageState = Readonly<{
    isLoading: boolean;
    requests: ReassignmentPageModel[];
}>;

export type ReceiveRequestsPayload = Pick<ReassignmentPageState, "requests">;

const initialState: ReassignmentPageState = {
    requests: [],
    isLoading: false
};

export const reassignmentSlice = createSlice({
    name: 'reassignment',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        receiveRequests: (state, action: PayloadAction<ReceiveRequestsPayload>) => {
            const { requests } = action.payload;
            state.isLoading = false;
            state.requests = requests;
        }
    }
});

export const getRequestsAsync = createAsyncThunk(
    'reassignment/getRequestsAsync',
    async (_, { dispatch }) => {

        // Dispatch request to intialize loading phase
        dispatch(startLoading());

        // Build http request and success handler in Promise<void> wrapper / complete processing
        try {
            const requests = await ReassignmentApi.getRequestsAsync();
            dispatch(stopLoading());
            const payload = { requests };
            dispatch(receiveRequests(payload));
        } catch (e) {
            dispatch(stopLoading());
            console.error(e);
        }
    }
);

export const saveReassignmentsAsync = createAsyncThunk(
    'reassignment/saveReassignmentsAsync',
    async (reassignments: ConfirmationPageModel[], { dispatch }) => {
        try {
            dispatch(startLoading());
            const response = await ReassignmentApi.saveReassignmentsAsync(reassignments);
            dispatch(getRequestsAsync);
            return response;
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.message + (typeof (e.response?.data) === 'string' ? ": " + e.response?.data : "");
            }
            return e.response.statusText;
        }
    }
);

export const { startLoading, stopLoading, receiveRequests } = reassignmentSlice.actions;

export default reassignmentSlice.reducer;