import { AdminApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

export const LookupTypeEnum = {
    None: '',
    ClientName: 'CLIENT_NAME',
    BorrowerType: 'BORROWER_TYPE',
    ProductType: 'PRODUCT_TYPE',
    Status: 'STATUS',
    User: 'USER'
} as const;
export type LookupTypeEnum = typeof LookupTypeEnum[keyof typeof LookupTypeEnum];

export type LookupData = Readonly<{
    key: number;
    value: string;
}>;

export type UserLookupData = Readonly<{
    key: string;
    value: string;
}>;

export type ReceiveLookupsPayload = Readonly<{
    lookupType: LookupTypeEnum,
    lookups: LookupData[];
}>;

export type LookupDataWithType = Readonly<{
    lookupType: LookupTypeEnum,
    lookup: LookupData;
}>;
export type LookupInfo = Readonly<{
    lookupType: LookupTypeEnum,
    lookups: LookupData[];
    lookup: LookupData;
}>;

export type LookupDataState = Readonly<{
    isLoading: boolean;
    ClientNameLookup: LookupInfo
    BorrowerTypeLookup: LookupInfo
    ProductTypeLookup: LookupInfo,
    StatusLookup: LookupInfo,
    UserLookup: UserLookupData[],
}>;

const initialState: LookupDataState = {
    isLoading: false,
    ClientNameLookup: { lookupType: LookupTypeEnum.ClientName, lookups: [], lookup: { key: 0, value: '' } },
    BorrowerTypeLookup: { lookupType: LookupTypeEnum.BorrowerType, lookups: [], lookup: { key: 0, value: '' } },
    ProductTypeLookup: { lookupType: LookupTypeEnum.ProductType, lookups: [], lookup: { key: 0, value: '' } },
    StatusLookup: { lookupType: LookupTypeEnum.Status, lookups: [], lookup: { key: 0, value: '' } },
    UserLookup: [],
};

export const lookupSlice = createSlice({
    name: 'lookup',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        receiveLookups: (state, action: PayloadAction<ReceiveLookupsPayload>) => {
            const { lookups, lookupType } = action.payload;
            switch (lookupType) {
                case LookupTypeEnum.ClientName: {
                    state.ClientNameLookup.lookups = lookups;
                    break;
                }
                case LookupTypeEnum.BorrowerType: {
                    state.BorrowerTypeLookup.lookups = lookups;
                    break;
                }
                case LookupTypeEnum.ProductType: {
                    state.ProductTypeLookup.lookups = lookups;
                    break;
                }
                case LookupTypeEnum.Status: {
                    state.StatusLookup.lookups = lookups;
                    break;
                }
            }
        },
        receiveUserLookups: (state, action: PayloadAction<UserLookupData[]>) => {
            state.UserLookup = action.payload;
        },
        loadLookup: (state, action: PayloadAction<LookupDataWithType>) => {
            const { lookupType, lookup } = action.payload;
            switch (lookupType) {
                case LookupTypeEnum.ClientName: {
                    state.ClientNameLookup.lookup = lookup;
                    break;
                }
                case LookupTypeEnum.BorrowerType: {
                    state.BorrowerTypeLookup.lookup = lookup;
                    break;
                }
                case LookupTypeEnum.ProductType: {
                    state.ProductTypeLookup.lookup = lookup;
                    break;
                }
                case LookupTypeEnum.Status: {
                    state.StatusLookup.lookup = lookup;
                    break;
                }
            }
        }
    }
});

export const getLookupsAsync = createAsyncThunk(
    'admin/getLookupsAsync',
    async (lookupType: LookupTypeEnum, { dispatch }) => {

        // Dispatch request to intialize loading phase
        dispatch(startLoading());

        // Build http request and success handler in Promise<void> wrapper / complete processing
        try {
            const lookups = await AdminApi.getLookupsAsync(lookupType);
            dispatch(stopLoading());
            const payload = { lookups, lookupType };
            dispatch(receiveLookups(payload));
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.response?.data;
            }
            return e;
        }
    }
);

export const getUserLookupsAsync = createAsyncThunk(
    'admin/getUserLookupsAsync',
    async (lookupType: LookupTypeEnum, { dispatch }) => {

        // Dispatch request to intialize loading phase
        dispatch(startLoading());

        // Build http request and success handler in Promise<void> wrapper / complete processing
        try {
            const lookups = await AdminApi.getUserLookupsAsync(lookupType);
            dispatch(stopLoading());
            dispatch(receiveUserLookups(lookups));
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.response?.data;
            }
            return e;
        }
    }
);

export const putLookupsAsync = createAsyncThunk(
    'admin/putLookupsAsync',
    async (lookupDataWithType: LookupDataWithType, { dispatch }) => {
        try {
            dispatch(startLoading());
            const newLookup = await AdminApi.putLookupsAsync(lookupDataWithType.lookupType, lookupDataWithType.lookup);
            dispatch(stopLoading());
            dispatch(loadLookup(newLookup));
            return newLookup;
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.response?.data;
            }
            return e;
        }
    }
);

export const postLookupsAsync = createAsyncThunk(
    'admin/postLookupsAsync',
    async (lookupDataWithType: LookupDataWithType, { dispatch }) => {
        try {
            dispatch(startLoading());
            const newLookup = await AdminApi.postLookupsAsync(lookupDataWithType.lookupType, lookupDataWithType.lookup);
            dispatch(stopLoading());
            dispatch(loadLookup(newLookup));
            return newLookup;
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.response?.data;
            }
            return e;
        }
    }
);

export const deleteLookupsAsync = createAsyncThunk(
    'admin/deleteLookupsAsync',
    async (lookupDataWithType: LookupDataWithType, { dispatch }) => {
        try {
            dispatch(startLoading());
            const status = await AdminApi.deleteLookupsAsync(lookupDataWithType.lookupType, lookupDataWithType.lookup.key);
            dispatch(stopLoading());
            return status;
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.response?.data;
            }
            return e;
        }
    }
);

export const { startLoading, stopLoading, receiveLookups, receiveUserLookups, loadLookup } = lookupSlice.actions;

export default lookupSlice.reducer;