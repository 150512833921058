import * as React from 'react';
import { useParams } from 'react-router-dom';
import { generatePath, useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import createDecorator from 'final-form-focus'
import MainForm from './MainForm';
import { useAppSelector, useAppDispatch } from '../../../store';
import { Spinner } from '../../../components';
import { getRelationAndPerformaceAsync, saveRelationAndPerformaceAsync, type PrivateEquityRequestState } from '../../../store/privateEquityRequestSlice';
import type RelationAndPerformaceModel from './RelationAndPerformaceModel';
import { toast } from 'react-toastify';
import { FORM_ERROR, type FormApi } from 'final-form';
import { Routes } from '../../../config';
import { useConfirm } from 'material-ui-confirm';

const focusOnError = createDecorator<RelationAndPerformaceModel, object>();
const RelationAndPerformaceTab: React.FunctionComponent<any> = ({ onBack, setShowDialog }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const confirm = useConfirm();

    const { requestId: requestIdDefault = '0', loanNumber: loanNumberDefault = '' } = useParams();
    const requestIdFromPrms = parseInt(requestIdDefault, 10);
    const location = useLocation();
    var q = queryString.parse(location.search);

    const [submittedValues, setSubmittedValues] = React.useState<RelationAndPerformaceModel | undefined>(
        undefined,
    );

    const privateEquityRequest = useAppSelector<PrivateEquityRequestState>((state) => state.privateEquityRequest);
    const requestId = privateEquityRequest.requestId;
    const isLoading = privateEquityRequest.isLoading;
    const subscription = { submitting: true, pristine: true };

    React.useEffect(() => {
        var payload = {
            requestId: requestIdFromPrms,
            loanNumber: (q.loanNumber == null ? "" : q.loanNumber.toString())
        };
        dispatch(getRelationAndPerformaceAsync(payload));
    }, []);

    const onSubmit = async (values: RelationAndPerformaceModel) => {
        //alert(JSON.stringify(values, undefined, 2));
        setSubmittedValues(values);
        toast.dismiss();
        values.requestId = requestId;
        var response = await dispatch(saveRelationAndPerformaceAsync(values));
        if (requestId === response.payload) {
            setTimeout(() => toast.success("Relationship and performace information is updated successfully."), 100);
        }
        else if (typeof (response.payload) === 'number') {
            const requestRoute = Routes.find((x) => x.name === 'Request')!;
            const requestPath = generatePath(requestRoute.path, { requestId: response.payload });
            navigate(requestPath);
            setTimeout(() => toast.success("New Private Equity Request has been created successfully."), 100);
        } else {
            let errroMsg = "Unable to save relationship and performace information. " + response.payload;
            setTimeout(() => toast.error(errroMsg, { autoClose: false }));
            return { [FORM_ERROR]: errroMsg }
        }
    };

    const onReset = (form: FormApi<any>) => {
        return () => {
            confirm({ description: `Are you sure to reset this form to original values?` })
                .then(() => {
                    setSubmittedValues(undefined);
                    form.reset();
                    toast.dismiss();
                    setTimeout(() => toast.warning("Form has been reset to original values."), 100);
                });
        };
    };

    if (isLoading) {
        return (<Spinner isLoading={isLoading} />)
    }
    return (
        <div className="wrap">
            <MainForm
                subscription={subscription}
                privateEquityRequest={privateEquityRequest}
                focusOnError={focusOnError}
                onSubmit={onSubmit}
                submittedValues={submittedValues}
                onReset={onReset}
                onBack={onBack}
                setShowDialog={setShowDialog}
            />
        </div>
    );
}

export default RelationAndPerformaceTab;