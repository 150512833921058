import type { GridColumns } from "@mui/x-data-grid-pro";
import { customStringOperators, customDateOperators } from "../../utils/gridOperators";

function getUnassigned({ value }: any) {
    return value == null || value == "" ? "Unassigned" : String(value);
}

var customStringOperator = customStringOperators();
var customDateOperator = customDateOperators();

export const ReassignmentGridColumns: GridColumns = [
    { field: "requestId", headerName: "RequestId", type: "number", "hide": true },
    {
        field: "loanNumber", headerName: "Loan #", width: 120,
        filterOperators: customStringOperator
    },
    {
        field: "borrowerName", headerName: "Borrower", minWidth: 180, flex: 1,
        filterOperators: customStringOperator
    },
    {
        field: "clientName", headerName: "Client Name", width: 180,
        filterOperators: customStringOperator
    },
    {
        field: "clientRelationshipStartDate",
        headerName: "Client Relationship Start Date",
        type: "date",
        width: 140,
        valueGetter: ({ value }) => value && new Date(value),
        filterOperators: customDateOperator
    },
    {
        field: "borrowerType", headerName: "Borrower Type", width: 130,
        filterOperators: customStringOperator
    },
    {
        field: "productType", headerName: "Product Type", width: 130,
        filterOperators: customStringOperator
    },
    {
        field: "portfolioManagerName", headerName: "Portfolio Manager", width: 150, valueGetter: getUnassigned,
        filterOperators: customStringOperator
    },
    {
        field: "portfolioAnalystName", headerName: "Portfolio Analyst", width: 150, valueGetter: getUnassigned,
        filterOperators: customStringOperator
    },
];

export const ConfirmationGridColumns: GridColumns = [
    { field: "requestId", headerName: "RequestId", type: "number", "hide": true },
    { field: "loanNumber", headerName: "Loan #", width: 120 },
    { field: "borrowerName", headerName: "Borrower", minWidth: 180, flex: 1 },
    {
        field: "portfolioManagerName", headerName: "Portfolio Manager (Before)", width: 180,
        renderHeader: () => (
            <span>Portfolio Manager <br />(Before)</span>
        ),
        valueGetter: getUnassigned
    },
    {
        field: "portfolioManagerNameAfter", headerName: "Portfolio Manager (After)", width: 180,
        renderHeader: () => (
            <span>Portfolio Manager <br />(After)</span>
        ),
        valueGetter: getUnassigned
    },
    {
        field: "portfolioAnalystName", headerName: "Portfolio Analyst (Before)", width: 180,
        renderHeader: () => (
            <span>Portfolio Analyst <br />(Before)</span>
        ),
        valueGetter: getUnassigned
    },
    {
        field: "portfolioAnalystNameAfter", headerName: "Portfolio Analyst (After)", width: 180,
        renderHeader: () => (
            <span>Portfolio Analyst <br />(After)</span>
        ),
        valueGetter: getUnassigned
    },
];