/* eslint-disable @typescript-eslint/no-redeclare */
import { AuthApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';

export const AuthStatusEnum = {
    FAIL: 'fail',
    NONE: 'none',
    PROCESS: 'process',
    SUCCESS: 'success'
} as const;

export type AuthStatusEnum = typeof AuthStatusEnum[keyof typeof AuthStatusEnum];

export type AuthState = {
    isAdmin?: boolean;
    isRequestor?: boolean;
    isReadOnly: boolean;
    status: AuthStatusEnum;
};

const initialState: AuthState = {
    isAdmin: false,
    isRequestor: false,
    isReadOnly: false,
    status: AuthStatusEnum.NONE
};

const replaceState = (
    state: AuthState,
    { isAdmin, isRequestor, isReadOnly, status }: AuthState
) => {
    state.isAdmin = isAdmin;
    state.isRequestor = isRequestor;
    state.isReadOnly = isReadOnly;
    state.status = status;
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthStatus: (state, action: PayloadAction<AuthStatusEnum>) => {
            state.status = action.payload;
        },
        setUserLogin: (state, action: PayloadAction<AuthState>) => {
            replaceState(state, action.payload);
        }
    }
});

export const getUserInfoAsync = createAsyncThunk(
    'auth/getuserinfo',
    async (_, { dispatch }) => {
        try {
            const authState = await AuthApi.getUserInfoAsync();
            //console.log(authState);
            const payload = { ...authState };
            dispatch(setUserLogin(payload));
        } catch (e) {
            dispatch(setAuthStatus(AuthStatusEnum.FAIL));
        }
    }
);

export const { setAuthStatus, setUserLogin } = authSlice.actions;

export default authSlice.reducer;