import { type GridColumns } from "@mui/x-data-grid-pro";
import { customStringOperators, customNumericOperators, customDateOperators } from "../../utils/gridOperators";
const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});
const decimalFormatter = new Intl.NumberFormat("en-US", {
    style: 'percent',
    minimumFractionDigits: 3,
    maximumFractionDigits: 3
});
var customStringOperator = customStringOperators();
var customNumericOperator = customNumericOperators(true);
var customDateOperator = customDateOperators();
export const PaidOffGridColumns: GridColumns = [
    {
        field: "requestId", headerName: "RequestId", type: "number", "hide": true
    },
    {
        field: "cisnumber", headerName: "CIS #", width: 120, "hide": true,
        filterOperators: customStringOperator
    },
    {
        field: "loanNumber", headerName: "Loan #", width: 120,
        filterOperators: customStringOperator
    },
    {
        field: "borrowerName", headerName: "Borrower", minWidth: 180, flex: 1,
        filterOperators: customStringOperator
    },
    {
        field: "clientName", headerName: "Client Name", width: 180,
        filterOperators: customStringOperator
    },
    {
        field: "borrowerType", headerName: "Borrower Type", width: 130,
        filterOperators: customStringOperator
    },
    {
        field: "dealType", headerName: "Deal Type", width: 130,
        filterOperators: customStringOperator
    },
    {
        field: "productType", headerName: "Product Type", width: 130,
        filterOperators: customStringOperator
    },
    {
        field: "relationshipManagerName", headerName: "Relationship Manager", width: 150,
        filterOperators: customStringOperator
    },
    {
        field: "portfolioManagerName", headerName: "Portfolio Manager", width: 150,
        filterOperators: customStringOperator
    },
    {
        field: "portfolioAnalystName", headerName: "Portfolio Analyst", width: 150,
        filterOperators: customStringOperator
    },
    {
        field: "terminationDate",
        headerName: "Paid Off Date",
        type: "date",
        width: 140,
        valueGetter: ({ value }) => value && new Date(value),
        filterOperators: customDateOperator
    },
    {
        field: "currentBankCommitment", headerName: "Current Bank Commitment (EWB Only)",
        type: "number",
        width: 130,
        cellClassName: "font-tabular-nums",
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
        filterOperators: customNumericOperator
    },
    {
        field: "currentOutstanding", headerName: "Current Outstanding",
        type: "number",
        width: 130,
        cellClassName: "font-tabular-nums",
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
        filterOperators: customNumericOperator
    },
    {
        field: "avgBankShrLedgerBalanceYtd", headerName: "Avg Bank Shr Ledger Balance YTD",
        type: "number",
        width: 130,
        cellClassName: "font-tabular-nums",
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
        filterOperators: customNumericOperator
    },
    {
        field: "baseRate", headerName: "Base Rate", width: 180,
        filterOperators: customStringOperator
    },
    {
        field: "spread", headerName: "Spread",
        type: "number",
        width: 130,
        cellClassName: "font-tabular-nums",
        valueFormatter: ({ value }) => decimalFormatter.format(Number(value/100)),
        filterOperators: customNumericOperator
    },
    {
        field: "interestRate", headerName: "Interest Rate",
        type: "number",
        width: 130,
        cellClassName: "font-tabular-nums",
        valueFormatter: ({ value }) => decimalFormatter.format(Number(value / 100)),
        filterOperators: customNumericOperator
    },
    {
        field: "securityDetails", headerName: "Security Details", width: 180,
        filterOperators: customStringOperator
    }
];