import type { ComponentType } from 'react';
import type { Params } from 'react-router-dom';
import * as Containers from '../containers';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import Forbidden from '../components/Forbidden';

export const TRANSITION_DEFAULT = {
    classNames: 'fade',
    timeout: { enter: 250, exit: 250 }
};

export type RouteComponent = ComponentType<any>;
export type TransitionMetaData = typeof TRANSITION_DEFAULT;

export type Route = Readonly<{
    name: string;
    path: string;
    icon?: IconProp;
    showInNav?: boolean;
    isPrivate?: boolean;
    Component: RouteComponent;
    transition: TransitionMetaData;
    params?: Readonly<Params<string>>;
}>;

export const Routes: Route[] = [
    {
        showInNav: false,
        isPrivate: true,
        name: 'Home',
        path: '/',
        Component: Containers.SummaryPage,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: false,
        isPrivate: false,
        name: 'Forbidden',
        path: '/forbidden',
        Component: Forbidden,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: true,
        isPrivate: true,
        name: 'Summary',
        path: '/summary',
        Component: Containers.SummaryPage,
        transition: {
            classNames: 'page-slide-right',
            timeout: { enter: 350, exit: 250 }
        }
    },
    {
        showInNav: false,
        isPrivate: true,
        path: '/request/:requestId',
        name: 'Request',
        Component: Containers.RequestInformation,
        transition: {
            classNames: 'page-slide-left',
            timeout: { enter: 350, exit: 250 }
        },
        params: {
            requestId: '0'
        }
    },
    {
        showInNav: true,
        isPrivate: true,
        path: '/reassignment',
        name: 'Reassignment',
        Component: Containers.ReassignmentPage,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: true,
        isPrivate: true,
        path: '/admin',
        name: 'Admin',
        Component: Containers.AdminPage,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: true,
        isPrivate: true,
        path: '/bulkedit',
        name: 'Bulk Edit',
        Component: Containers.BulkEditPage,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: true,
        isPrivate: true,
        path: '/paidoff',
        name: 'Paid Off',
        Component: Containers.PaidOffPage,
        transition: TRANSITION_DEFAULT
    }
];
