import { useEffect, type FunctionComponent } from "react";
import { useLocation, Route, Routes } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Routes as routes, TRANSITION_DEFAULT } from "../config";
import { LoggedInUserInfo } from "../hooks";
import { useAppDispatch } from "../store";
import { AuthStatusEnum, getUserInfoAsync, type AuthState } from "../store/authSlice";
import { CanViewPage } from "../utils";
import Forbidden from "./Forbidden";
import NotFound from "./NotFound";
import Spinner from "./Spinner";

const PrivateRoutes: FunctionComponent = () => {
    const location = useLocation();
    const cssKey = location.pathname?.split('/')[1] || '/';
    const curRoute = routes.find((x) => (x.path === cssKey) || (x.name.toLowerCase() === cssKey.toLowerCase()));
    const { timeout, classNames } = curRoute?.transition ?? TRANSITION_DEFAULT;

    const dispatch = useAppDispatch();
    const userInfo: AuthState = LoggedInUserInfo();
    useEffect(() => {
        dispatch(getUserInfoAsync());
    }, []);

    if (!userInfo || userInfo.status == AuthStatusEnum.NONE) {
        return <Spinner isLoading={true} />
    }
    return (
        <SwitchTransition mode="out-in">
            <CSSTransition
                key={cssKey}
                timeout={timeout}
                classNames={classNames}
            >
                <Routes location={location}>
                    {
                        routes.map(({ name, isPrivate, path, Component }) => {
                            var canView = CanViewPage(name, isPrivate, userInfo);
                            return <Route
                                key={path}
                                path={path}
                                element={canView ? <Component /> : <Forbidden />}
                            />
                        })
                    }
                    <Route key="*" path="*" element={<NotFound />} />
                </Routes>
            </CSSTransition>
        </SwitchTransition>
    );
}

export default PrivateRoutes;