import * as React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';

import { Button, Grid, InputAdornment, Stack } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import * as Yup from 'yup';
import {
    makeValidate,
    makeRequired,
    TextField
} from 'mui-rff';

import Styles from '../Styles';
import { PricingAndFeeSchema } from './PricingAndFeeSchema';
import { type PrivateEquityRequestState } from '../../../store/privateEquityRequestSlice';
import type PricingAndFeeModel from './PricingAndFeeModel';
import { formatCurrency, formatDecimal } from '../../../utils';

const schema = Yup.object().shape(PricingAndFeeSchema);

const validate = makeValidate(schema, (err) => {
    const { message } = err;
    return message;
});

const required = makeRequired(schema);
interface MainFormProps {
    subscription: any,
    privateEquityRequest: PrivateEquityRequestState,
    focusOnError: any,
    onSubmit: any,
    onReset: any,
    onBack: any,
    submittedValues: PricingAndFeeModel | undefined,
    setShowDialog: any
}

const MainForm: React.FunctionComponent<MainFormProps> = ({ subscription, privateEquityRequest, focusOnError, onSubmit, submittedValues, onReset, onBack, setShowDialog }) => {

    const initialValues: PricingAndFeeModel = privateEquityRequest.pricingAndFee;
    const helperText = '* Required';
    const disabledTextProps = { disabled: initialValues.isReadonly };

    const formFields = [
        //index=0
        <TextField label="Loan Number" name="loanNumber" disabled />,
        <TextField label="Borrower Name" name="borrowerName" disabled />,

        //index=2
        <TextField label="Base Rate" name="baseRate" disabled />,
        <Field label="Interest Rate" name="interestRate" disabled format={formatDecimal}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="Spread" name="spread" disabled format={formatDecimal}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="Floor Rate" name="floorRate" disabled format={formatDecimal}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,

        //index=6
        <Field name="upfrontFee" label="Upfront Fee" required={required.upfrontFee} helperText={helperText}
            formatOnBlur format={formatDecimal} {...disabledTextProps}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="Unused Fee" name="unusedFee" required={required.unusedFee} helperText={helperText}
            formatOnBlur format={formatDecimal} {...disabledTextProps}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="Tiered Unused Fee" name="tieredUnused" required={required.tieredUnused}
            formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="Advance Fee" name="advanceFee" required={required.advanceFee} helperText={helperText}
            formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,

        //index=10
        <Field label="Extension Fee" name="extensionFee" required={required.extensionFee} helperText={helperText}
            formatOnBlur format={formatDecimal} {...disabledTextProps}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="LC Fee" name="lcfee" required={required.lcfee} helperText={helperText}
            formatOnBlur format={formatDecimal} {...disabledTextProps}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="Syndication Fee" name="syndicationFee" required={required.syndicationFee} helperText={helperText}
            formatOnBlur format={formatDecimal} {...disabledTextProps}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="Anniversary Fee" name="anniversaryFee" required={required.anniversaryFee} helperText={helperText}
            formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
    ];

    return (
        <Styles>
            <Form
                onSubmit={onSubmit}
                initialValues={submittedValues ? submittedValues : initialValues}
                subscription={subscription}
                validate={validate}
                key={subscription as any}
                decorators={[focusOnError]}
                render={({ handleSubmit, submitting, pristine, form }) => (
                    <form onSubmit={handleSubmit} noValidate={true}>
                        <Stack direction="row" justifyContent="flex-start" spacing={2} sx={{ py: 1 }}>
                            <Button type="button" variant="outlined" size="medium" onClick={onBack} startIcon={<ArrowBackOutlinedIcon />}>
                                Back
                            </Button>
                        </Stack>
                        <Grid container>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="flex-start" spacing={1} py={1} my={2}>
                                    {
                                        formFields.slice(0, 1).map((field, index) => (
                                            <Grid item key={index} sx={{ mr: 1 }} xs={4}>
                                                {field}
                                            </Grid>
                                        ))
                                    }
                                    {
                                        formFields.slice(1, 2).map((field, index) => (
                                            <Grid item key={index} xs={8}>
                                                {field}
                                            </Grid>
                                        ))
                                    }
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <fieldset className="wrapper-fieldset">
                                    <legend className="header">Pricing & Fees</legend>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {
                                                formFields.slice(2, 6).map((field, index) => (
                                                    <Grid item key={index} p={1}>
                                                        {field}
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        <Grid item xs={4}>
                                            {
                                                formFields.slice(6, 10).map((field, index) => (
                                                    <Grid item key={index} p={1}>
                                                        {field}
                                                    </Grid>
                                                ))}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {
                                                formFields.slice(10, 14).map((field, index) => (
                                                    <Grid item key={index} p={1}>
                                                        {field}
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>
                        </Grid>
                        {
                            !initialValues.isReadonly &&
                            (
                                <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ py: 1 }}>
                                    <Button type="button" variant="contained" size="medium" color="secondary" onClick={onReset(form)} disabled={submitting || pristine}>
                                        Reset
                                    </Button>
                                    <Button type="submit" variant="contained" size="medium" color="primary" disabled={submitting || pristine} >
                                        Submit
                                    </Button>
                                </Stack>)
                        }
                        <FormSpy
                            subscription={subscription}
                            onChange={props => {
                                if (props.submitting)
                                    setShowDialog(false);
                                else
                                    setShowDialog(!props.pristine);
                            }}
                        />
                    </form>
                )}
            />
        </Styles>
    );
};

export default MainForm;