import { BaseService } from './base.service';
import type { PaidOffPageModel } from '../store/paidOffSlice';

/**
 * PaidOff API abstraction layer communication via Axios (typescript singleton pattern)
 */
class PaidOffService extends BaseService {
    private static _paidOffService: PaidOffService;
    private static _controller: string = 'PaidOff';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): PaidOffService {
        return this._paidOffService || (this._paidOffService = new this(this._controller));
    }

    public async getRequestsAsync(): Promise<PaidOffPageModel[]> {
        const url = `GetPaidOffPageRequests`;
        const { data } = await this.$http.get<PaidOffPageModel[]>(url);
        return data;
    }
}

export const PaidOffApi = PaidOffService.Instance;
