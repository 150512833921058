import * as React from 'react';
import { useParams } from 'react-router-dom';
import { generatePath, useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import createDecorator from 'final-form-focus'
import MainForm from './MainForm';
import { useAppSelector, useAppDispatch } from '../../../store';
import { getLookupsAsync, getUserLookupsAsync, LookupTypeEnum, type LookupDataState } from '../../../store/adminSlice';
import { Spinner } from '../../../components';
import { getGeneralInformationAsync, saveGeneralInformationAsync, type PrivateEquityRequestState } from '../../../store/privateEquityRequestSlice';
import type GeneralInformationModel from './GeneralInformationModel';
import { toast } from 'react-toastify';
import { FORM_ERROR } from 'final-form';
import { Routes } from '../../../config';

const focusOnError = createDecorator<GeneralInformationModel, object>();
const GeneralInformationTab: React.FunctionComponent<any> = ({ onBack, setShowDialog }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { requestId: requestIdDefault = '0' } = useParams();
    const requestIdFromPrms = parseInt(requestIdDefault, 10);

    const location = useLocation();
    var q = queryString.parse(location.search);

    const [submittedValues, setSubmittedValues] = React.useState<GeneralInformationModel | undefined>(
        undefined,
    );

    const lookup = useAppSelector<LookupDataState>((state) => state.lookup);
    const privateEquityRequest = useAppSelector<PrivateEquityRequestState>((state) => state.privateEquityRequest);
    const requestId = privateEquityRequest.requestId;
    const isLoading = privateEquityRequest.isLoading;
    const subscription = { submitting: true, pristine: true };

    React.useEffect(() => {
        dispatch(getLookupsAsync(LookupTypeEnum.ClientName));
        dispatch(getLookupsAsync(LookupTypeEnum.BorrowerType));
        dispatch(getLookupsAsync(LookupTypeEnum.ProductType));
        dispatch(getLookupsAsync(LookupTypeEnum.Status));
        dispatch(getUserLookupsAsync(LookupTypeEnum.User));
        var payload = {
            requestId: requestIdFromPrms,
            loanNumber: (q.loanNumber == null ? "" : q.loanNumber.toString())
        };
        dispatch(getGeneralInformationAsync(payload)).then((response: any) => {
            if (response.payload == 404 || response.payload == 400) {
                navigate("/NotFound", { replace: true });
            }
        });
    }, []);

    const onSubmit = async (values: GeneralInformationModel) => {
        setSubmittedValues(values);
        toast.dismiss();
        values.requestId = requestId;
        var response = await dispatch(saveGeneralInformationAsync(values));
        if (requestId === response.payload) {
            setSubmittedValues(undefined);/*this resets state which reloads the child component with latest data*/
            setTimeout(() => toast.success("Private Equity Request has been updated successfully."), 100);
        }
        else if (typeof (response.payload) === 'number') {
            setSubmittedValues(undefined);
            const requestRoute = Routes.find((x) => x.name === 'Request')!;
            const requestPath = generatePath(requestRoute.path, { requestId: response.payload });
            navigate(requestPath);
            setTimeout(() => toast.success("New Private Equity Request has been created successfully."), 100);
        } else {
            let errroMsg = "Unable to save Private Equity Request. " + response.payload;
            setTimeout(() => toast.error(errroMsg, { autoClose: false }));
            return { [FORM_ERROR]: errroMsg }
        }
    };
    if (isLoading) {
        return (<Spinner isLoading={isLoading} />)
    }
    return (
        <div className="wrap">
            <MainForm
                subscription={subscription}
                lookup={lookup}
                generalInformation={privateEquityRequest.generalInformation}
                focusOnError={focusOnError}
                onSubmit={onSubmit}
                submittedValues={submittedValues}
                setSubmittedValues={setSubmittedValues}
                onBack={onBack}
                setShowDialog={setShowDialog}
            />
        </div>
    );
}

export default GeneralInformationTab;