import * as React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
    type GridRowsProp,
    type GridRowModesModel,
    GridRowModes,
    GridToolbarContainer,
} from '@mui/x-data-grid-pro';

interface LookupGridToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
    pageSize: number,
    setPage: (newPage: (oldPage: number) => number) => void;
}

const LookupGridToolbar = React.memo<LookupGridToolbarProps>((props: LookupGridToolbarProps) => {
    const { setRows, setRowModesModel, setPage, pageSize } = props;

    const handleAddRecord = () => {
        const key = 0;
        let count: number = 0;
        let lastPageNumber: number = 0;
        setRows((oldRows) => {
            count = oldRows.length + 1;
            lastPageNumber = Math.ceil(count / pageSize);
            if (oldRows.filter((row) => row.key == 0).length > 0) {
                return [...oldRows]
            }
            return [...oldRows, { key, value: '' }]
        });
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [key]: { mode: GridRowModes.Edit, fieldToFocus: 'value' },
        }));
        setPage(() => {
            return lastPageNumber;
        });
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleAddRecord}>
                Add record
            </Button>
        </GridToolbarContainer>
    );
});

export default LookupGridToolbar;