import * as React from 'react';
import {
    GridToolbar,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { Toolbar } from '@mui/material';

interface SummaryGridToolbarProps {
    setPage: (newPage: (oldPage: number) => number) => void;
}

const SummaryGridToolbar = React.memo<SummaryGridToolbarProps>((props: SummaryGridToolbarProps) => {
    const handleSearchRecord = (searchInput: string) => {
        return searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
    };

    return (
        <Toolbar>
            <GridToolbar printOptions={{ disableToolbarButton: true }} />
            <GridToolbarQuickFilter
                quickFilterParser={handleSearchRecord}
                style={{ position: 'absolute', right: 0 }}
            />
        </Toolbar>
    )
});

export default SummaryGridToolbar;