import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useNavigate, generatePath } from 'react-router-dom';
import { Routes } from '../../config';
import type { ReceiveRequestsPayload } from '../../store/summarySlice';
import { SummaryGridColumns } from './SummaryGridColumns';
import SummaryGridToolbar from './SummaryGridToolbar';

const SummaryGrid = React.memo<ReceiveRequestsPayload>(({ requests }) => {

    const [pageSize, setPageSize] = React.useState<number>();
    const [page, setPage] = React.useState<number>(0);

    const navigate = useNavigate();

    function handleOnClick(rowData: any) {
        const requestRoute = Routes.find((x) => x.name === 'Request')!;
        var requestPath = generatePath(requestRoute.path, { requestId: rowData.requestId });
        if (rowData.requestId == 0)
            requestPath = requestPath + "?loanNumber=" + rowData.loanNumber;
        navigate(requestPath);
    }
    return (
        <div style={{ height: 720,width: '100%' }}>
            <DataGridPro
                initialState={{
                    pagination: {
                        pageSize: 10,
                    },
                }}
                disableColumnMenu={true}
                disableSelectionOnClick
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                pagination
                columns={SummaryGridColumns}
                rows={requests}
                getRowId={(row) => row.id}
                isCellEditable={() => false}
                components={{ Toolbar: SummaryGridToolbar }}
                onRowDoubleClick={(param) => handleOnClick(param.row)} />
        </div>
    );
});

export default SummaryGrid;