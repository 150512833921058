import { getGridStringOperators, type GridFilterItem, type GridCellParams, getGridNumericOperators, getGridDateOperators } from "@mui/x-data-grid";

export function addAfter(array: any[], index: number, newItem: any) {
    return [
        ...array.slice(0, index),
        newItem,
        ...array.slice(index)
    ];
}

export const customStringOperators = () => {
    var stringOperators = getGridStringOperators().filter(
        (operator) => operator.value !== 'equals' && operator.value !== 'isAnyOf'
    ).map((operator) => {
        switch (operator.value) {
            case "contains": {
                operator.label = "Contains";
                break;
            }
            case "startsWith": {
                operator.label = "Begins with";
                break;
            }
            case "endsWith": {
                operator.label = "Ends with";
                break;
            }
            case "isEmpty": {
                operator.label = "Blanks";
                break;
            }
            case "isNotEmpty": {
                operator.label = "Not Blanks";
                break;
            }
            default:
                break;
        }
        return operator;
    });
    var doesNotContain = {
        label: 'Does not contain',
        value: 'doesNotContain',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                if (params.value == null)
                    return true;
                var paramVal = params.value + "";
                var filterVal = filterItem.value + "";
                return !paramVal.toLowerCase().includes(filterVal.toLowerCase());
            };
        },
        InputComponent: stringOperators[0].InputComponent,
        InputComponentProps: stringOperators[0].InputComponentProps,
    };
    return addAfter(stringOperators, 1, doesNotContain);
};

export const customObjectStringOperators = () => {
    var stringOperators = getGridStringOperators().filter(
        (operator) => operator.value !== 'equals' && operator.value !== 'isAnyOf'
    ).map((operator) => {
        switch (operator.value) {
            case "contains": {
                operator.label = "Contains";
                operator.getApplyFilterFn = (filterItem: GridFilterItem) => {
                    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                        return null;
                    }
                    return (params: GridCellParams): boolean => {
                        if (params.value == null)
                            return false;
                        var paramVal = (params.value?.label ?? params.value) + "";
                        var filterVal = filterItem.value + "";
                        return paramVal.toLowerCase().includes(filterVal.toLowerCase());
                    };
                }
                break;
            }
            case "startsWith": {
                operator.label = "Begins with";
                operator.getApplyFilterFn = (filterItem: GridFilterItem) => {
                    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                        return null;
                    }
                    return (params: GridCellParams): boolean => {
                        if (params.value == null)
                            return false;
                        var paramVal = (params.value?.label ?? params.value) + "";
                        var filterVal = filterItem.value + "";
                        return paramVal.toLowerCase().startsWith(filterVal.toLowerCase());
                    };
                }
                break;
            }
            case "endsWith": {
                operator.label = "Ends with";
                operator.getApplyFilterFn = (filterItem: GridFilterItem) => {
                    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                        return null;
                    }
                    return (params: GridCellParams): boolean => {
                        if (params.value == null)
                            return false;
                        var paramVal = (params.value?.label ?? params.value) + "";
                        var filterVal = filterItem.value + "";
                        return paramVal.toLowerCase().endsWith(filterVal.toLowerCase());
                    };
                }
                break;
            }
            case "isEmpty": {
                operator.label = "Blanks";
                operator.getApplyFilterFn = (filterItem: GridFilterItem) => {
                    if (!filterItem.columnField || !filterItem.operatorValue) {
                        return null;
                    }
                    return (params: GridCellParams): boolean => {
                        if (params.value == null)
                            return true;
                        var paramVal = (params.value?.label ?? params.value) + "";
                        return paramVal == "";
                    };
                }
                break;
            }
            case "isNotEmpty": {
                operator.label = "Not Blanks";
                operator.getApplyFilterFn = (filterItem: GridFilterItem) => {
                    if (!filterItem.columnField || !filterItem.operatorValue) {
                        return null;
                    }
                    return (params: GridCellParams): boolean => {
                        if (params.value == null)
                            return true;
                        var paramVal = (params.value?.label ?? params.value) + "";
                        return paramVal != "";
                    };
                }
                break;
            }
            default:
                break;
        }
        return operator;
    });
    var doesNotContain = {
        label: 'Does not contain',
        value: 'doesNotContain',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                if (params.value == null)
                    return true;
                var paramVal = (params.value?.label ?? params.value) + "";
                var filterVal = filterItem.value + "";
                return !paramVal.toLowerCase().includes(filterVal.toLowerCase());
            };
        },
        InputComponent: stringOperators[0].InputComponent,
        InputComponentProps: stringOperators[0].InputComponentProps,
    };
    return addAfter(stringOperators, 1, doesNotContain);
};

export const customNumericOperators = (withCurrencyFilter: boolean) => {
    var numericOperators = getGridNumericOperators().filter(
        (operator) => operator.value === '=' || operator.value === '!=' || operator.value === '>=' || operator.value === '<='
    ).map((operator) => {
        switch (operator.value) {
            case "=": {
                operator.label = "Equals";
                break;
            }
            case "!=": {
                operator.label = "Not equals";
                break;
            }
            case ">=": {
                operator.label = "Greater than or equal";
                break;
            }
            case "<=": {
                operator.label = "Less than or equal";
                break;
            }
            default:
                break;
        }
        return operator;
    });
    if (!withCurrencyFilter)
        return numericOperators;
    var lessThan25K = {
        label: '$0.00 to $25,000.00',
        value: 'lessThan25K',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                if (params.value == null)
                    return true;
                var val = Number(params.value);
                return val >= 0 && val <= 25000
            };
        }
    };
    var lessThan50K = {
        label: '$25,000.00 to $50,000.00',
        value: 'lessThan50K',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                if (params.value == null)
                    return false;
                var val = Number(params.value);
                return val >= 25000 && val <= 50000
            };
        },
        InputComponent: null,
        InputComponentProps: null,
    };
    var lessThan100K = {
        label: '$50,000.00 to $100,000.00',
        value: 'lessThan100K',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                if (params.value == null)
                    return false;
                var val = Number(params.value);
                return val >= 50000 && val <= 100000
            };
        },
        InputComponent: null,
        InputComponentProps: null,
    };
    var lessThan250K = {
        label: '$100,000.00 to $250,000.00',
        value: 'lessThan250K',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                if (params.value == null)
                    return false;
                var val = Number(params.value);
                return val >= 100000 && val <= 250000
            };
        },
        InputComponent: null,
        InputComponentProps: null,
    };
    var moreThan250K = {
        label: '>= $250,000.00',
        value: 'moreThan250K',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                if (params.value == null)
                    return false;
                var val = Number(params.value);
                return val >= 250000
            };
        },
        InputComponent: null,
        InputComponentProps: null,
    };
    numericOperators = addAfter(numericOperators, 0, lessThan25K);
    numericOperators = addAfter(numericOperators, 1, lessThan50K);
    numericOperators = addAfter(numericOperators, 2, lessThan100K);
    numericOperators = addAfter(numericOperators, 3, lessThan250K);
    numericOperators = addAfter(numericOperators, 4, moreThan250K);
    return numericOperators;
};

export const customDateOperators = () => {
    var dateOperators = getGridDateOperators().filter(
        (operator) => operator.value === 'onOrAfter' || operator.value === 'onOrBefore' || operator.value === 'isEmpty' || operator.value === 'isNotEmpty'
    ).map((operator) => {
        switch (operator.value) {
            case "onOrAfter": {
                operator.label = "On or After";
                break;
            }
            case "onOrBefore": {
                operator.label = "On or Before";
                break;
            }
            case "isEmpty": {
                operator.label = "Blanks";
                break;
            }
            case "isNotEmpty": {
                operator.label = "Not Blanks";
                break;
            }
            default:
                break;
        }
        return operator;
    });
    var pastQuarter = {
        label: 'Past quarter',
        value: 'pastQuarter',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                if (params.value == null)
                    return false;
                var today = new Date(),
                    quarter = Math.floor((today.getMonth() / 3)),
                    startDate = new Date(today.getFullYear(), quarter * 3, 1);
                return params.value >= startDate;
            };
        }
    };
    var pastYear = {
        label: 'Past year',
        value: 'pastYear',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                if (params.value == null)
                    return false;
                var today = new Date(),
                    startDate = today.setMonth(today.getMonth() - 12);
                return params.value >= startDate;
            };
        }
    };
    var past3Years = {
        label: 'Past 3 years',
        value: 'past3Years',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                if (params.value == null)
                    return false;
                var today = new Date(),
                    startDate = today.setMonth(today.getMonth() - 36);
                return params.value >= startDate;
            };
        }
    };
    var YearToDate = {
        label: 'Year to date',
        value: 'YearToDate',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                if (params.value == null)
                    return false;
                var today = new Date(),
                    startDate = new Date(today.getFullYear(), 1, 1);
                return params.value >= startDate;
            };
        }
    };
    dateOperators = addAfter(dateOperators, 0, pastQuarter);
    dateOperators = addAfter(dateOperators, 1, pastYear);
    dateOperators = addAfter(dateOperators, 2, past3Years);
    dateOperators = addAfter(dateOperators, 3, YearToDate);
    return dateOperators;
};