import * as React from 'react';
import { toast } from 'react-toastify';

import { Button, createFilterOptions, Grid, InputAdornment, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useConfirm } from 'material-ui-confirm';

import { type FormApi } from 'final-form';
import { Field, Form, FormSpy } from 'react-final-form';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import * as Yup from 'yup';
import {
    Autocomplete,
    DatePicker,
    makeValidate,
    makeRequired,
    TextField,
    type RadioData,
    Radios,
} from 'mui-rff';

import Styles from '../Styles';
import type GeneralInformationModel from './GeneralInformationModel';
import { GeneralInformationSchema } from './GeneralInformationSchema';
import { type LookupData, type LookupDataState, type UserLookupData } from '../../../store/adminSlice';
import { formatCurrency, formatDecimal } from '../../../utils';

const schema = Yup.object().shape(GeneralInformationSchema);

const validate = makeValidate(schema, (err) => {
    const { message } = err;
    return message;
});

const filterOptions = createFilterOptions({
    matchFrom: 'start'
});

const required = makeRequired(schema);

interface MainFormProps {
    subscription: any,
    lookup: LookupDataState,
    generalInformation: GeneralInformationModel,
    focusOnError: any,
    onSubmit: any,
    submittedValues: GeneralInformationModel | undefined,
    setSubmittedValues: any,
    onBack: any,
    setShowDialog: any
}

const dateFnsUtilsProps = { // make sure all required component's inputs/Props keys&types match
    dateFunsUtils: DateFnsUtils
}

const MainForm: React.FunctionComponent<MainFormProps> = ({ subscription, lookup, generalInformation, focusOnError, onSubmit, submittedValues, setSubmittedValues, onBack, setShowDialog }) => {

    const confirm = useConfirm();

    const clientNameLookups: LookupData[] = lookup.ClientNameLookup.lookups;
    const borrowerTypeLookups: LookupData[] = lookup.BorrowerTypeLookup.lookups;
    const productTypeLookups: LookupData[] = lookup.ProductTypeLookup.lookups;
    const userLookups: UserLookupData[] = lookup.UserLookup;
    const initialValues: GeneralInformationModel = generalInformation;

    const [clientNameId, setClientNameId] = React.useState<any>({ label: initialValues.clientName, value: initialValues.clientNameId });
    const handleClientNameIdChange = (e: React.SyntheticEvent, v: React.SetStateAction<any>) => {
        setClientNameId(v);
    };
    const [borrowerTypeId, setBorrowerTypeId] = React.useState<any>({ label: initialValues.borrowerType, value: initialValues.borrowerTypeId });
    const handleBorrowerTypeIdChange = (e: React.SyntheticEvent, v: React.SetStateAction<any>) => {
        setBorrowerTypeId(v);
    };
    const [productTypeId, setProductTypeId] = React.useState<any>({ label: initialValues.productType, value: initialValues.productTypeId });
    const handleProductTypeIdChange = (e: React.SyntheticEvent, v: React.SetStateAction<any>) => {
        setProductTypeId(v);
    };
    const [relationshipManagerEmail, setRelationshipManagerEmail] = React.useState<any>({ label: initialValues.relationshipManagerName, value: initialValues.relationshipManagerEmail });
    const handleRelationshipManagerEmailChange = (e: React.SyntheticEvent, v: React.SetStateAction<any>) => {
        setRelationshipManagerEmail(v);
    };
    const [portfolioManagerEmail, setPortfolioManagerEmail] = React.useState<any>({ label: initialValues.portfolioManagerName, value: initialValues.portfolioManagerEmail });
    const handlePortfolioManagerEmailChange = (e: React.SyntheticEvent, v: React.SetStateAction<any>) => {
        setPortfolioManagerEmail(v);
    };
    const [portfolioAnalystEmail, setPortfolioAnalystEmail] = React.useState<any>({ label: initialValues.portfolioAnalystName, value: initialValues.portfolioAnalystEmail });
    const handlePortfolioAnalystEmailChange = (e: React.SyntheticEvent, v: React.SetStateAction<any>) => {
        setPortfolioAnalystEmail(v);
    };
    React.useEffect(() => {

    }, []);

    const onReset = (form: FormApi<any>) => {
        return () => {
            confirm({ description: `Are you sure to reset this form to original values?` })
                .then(() => {
                    setSubmittedValues(undefined);
                    form.reset();
                    setClientNameId({ label: initialValues.clientName, value: initialValues.clientNameId });
                    setBorrowerTypeId({ label: initialValues.borrowerType, value: initialValues.borrowerTypeId });
                    setProductTypeId({ label: initialValues.productType, value: initialValues.productTypeId });
                    setRelationshipManagerEmail({ label: initialValues.relationshipManagerName, value: initialValues.relationshipManagerEmail });
                    setPortfolioManagerEmail({ label: initialValues.portfolioManagerName, value: initialValues.portfolioManagerEmail });
                    setPortfolioAnalystEmail({ label: initialValues.portfolioAnalystName, value: initialValues.portfolioAnalystEmail });
                    toast.dismiss();
                    setTimeout(() => toast.warning("Form has been reset to original values."), 100);
                });
        };
    };

    const helperTextProps = {
        helperText: '* Required'
    };
    const disabledTextProps = { disabled: initialValues.isReadonly };

    const committedOrUnCommitedData: RadioData[] = [
        { label: 'Committed', value: 'committed' },
        { label: 'Uncommitted', value: 'uncommitted' },
    ];
    const revolvingOrTermData: RadioData[] = [
        { label: 'Revolving Credit Facility', value: 'revolvingcreditfacility' },
        { label: 'Non-Revolving Credit Facility', value: 'non-revolvingcreditfacility' },
        { label: 'Term', value: 'term' },
    ];
    const depositRequirementData: RadioData[] = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
    ];

    const formFields = [
        //index=0
        <TextField label="CIS #" name="cisnumber" disabled />,
        <TextField label="Loan Number" name="loanNumber" disabled />,
        <TextField label="Borrower Name" name="borrowerName" disabled />,
        <Autocomplete
            label="Client Name"
            name="clientNameId"
            required={required.clientNameId}
            {...helperTextProps}
            options={clientNameLookups.map(input => ({ label: input.value, value: input.key.toString() }))}
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => option?.label || ""}
            value={clientNameId || null}
            onChange={handleClientNameIdChange}
            defaultValue=""
            fieldProps={{ filterOptions: filterOptions }}
            {...disabledTextProps}
        />,
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Client Relationship Start Date" name="clientRelationshipStartDate"
                required={required.clientRelationshipStartDate} {...helperTextProps} {...dateFnsUtilsProps} {...disabledTextProps} />
        </LocalizationProvider>,
        <TextField label="Paid Off Date" name="terminationDate" disabled sx={initialValues.isPaidOff === false ? { display: { xs: 'none' } } : {}} />,
        <TextField label="Current Risk Rate Code" name="currentRiskRateCode" disabled />,

        //index=7
        <TextField label="Status" name="status" disabled />,
        <TextField label="Term" name="term" disabled />,
        <Radios
            label="Committed or Uncommitted"
            name="committed"
            data={committedOrUnCommitedData}
            disabled
        />,
        <Radios
            label="Revolving or Term"
            name="revolvingOrTerm"
            data={revolvingOrTermData}
            disabled
        />,
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Original Date Booked" name="originalDateBooked" disabled {...dateFnsUtilsProps} />
        </LocalizationProvider>,
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Extension Date" name="extensionDate" disabled {...dateFnsUtilsProps} />
        </LocalizationProvider>,
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Maturity Date" name="maturityDate" disabled {...dateFnsUtilsProps} />
        </LocalizationProvider>,
        <Radios
            label="Deposit Requirement"
            name="depositRequirement"
            data={depositRequirementData}
            required={required.depositRequirement}
            {...helperTextProps}
            {...disabledTextProps}
        />,

        //index=15
        <TextField label="Unit" name="unit" disabled />,
        <Autocomplete
            label="Relationship Manager"
            name="relationshipManagerEmail"
            options={userLookups.map(input => ({ label: input.value, value: input.key }))}
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => option?.label || ""}
            value={relationshipManagerEmail || null}
            onChange={handleRelationshipManagerEmailChange}
            defaultValue=""
            disabled
        />,
        <Autocomplete
            label="Portfolio Manager"
            name="portfolioManagerEmail"
            required={required.portfolioManagerEmail}
            {...helperTextProps}
            options={userLookups.map(input => ({ label: input.value, value: input.key }))}
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => option?.label || ""}
            value={portfolioManagerEmail || null}
            onChange={handlePortfolioManagerEmailChange}
            defaultValue=""
            fieldProps={{ filterOptions: filterOptions }}
            {...disabledTextProps}
        />,
        <Autocomplete
            label="Portfolio Analyst"
            name="portfolioAnalystEmail"
            required={required.portfolioAnalystEmail}
            {...helperTextProps}
            options={userLookups.map(input => ({ label: input.value, value: input.key }))}
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => option?.label || ""}
            value={portfolioAnalystEmail || null}
            onChange={handlePortfolioAnalystEmailChange}
            defaultValue=""
            fieldProps={{ filterOptions: filterOptions }}
            {...disabledTextProps}
        />,

        //index=19
        <Autocomplete
            label="Borrower Type"
            name="borrowerTypeId"
            required={required.borrowerTypeId}
            {...helperTextProps}
            options={borrowerTypeLookups.map(input => ({ label: input.value, value: input.key.toString() }))}
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => option?.label || ""}
            value={borrowerTypeId || null}
            onChange={handleBorrowerTypeIdChange}
            defaultValue=""
            fieldProps={{ filterOptions: filterOptions }}
            {...disabledTextProps}
        />,
        <Autocomplete
            label="Product Type"
            name="productTypeId"
            required={required.productTypeId}
            {...helperTextProps}
            options={productTypeLookups.map(input => ({ label: input.value, value: input.key.toString() }))}
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => option?.label || ""}
            value={productTypeId || null}
            onChange={handleProductTypeIdChange}
            defaultValue=""
            fieldProps={{ filterOptions: filterOptions }}
            {...disabledTextProps}
        />,
        <TextField label="Deal Type" name="dealType" disabled />,
        <TextField label="Agent Bank" name="agentBank" disabled />,

        //index=23
        <Field label="Current Bank Commitment (EWB Only)" name="currentBankCommitment" disabled format={formatCurrency}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="Total Current Facility Amount (Global)" name="totalCurrentFacilityAmount" required={required.totalCurrentFacilityAmount}
            {...helperTextProps} {...disabledTextProps} formatOnBlur format={formatCurrency}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="EWB Share" name="ewbshare" disabled sx={initialValues.requestId == 0 ? { display: { xs: 'none' } } : {}} format={formatDecimal}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="Current Outstanding" name="currentOutstanding" disabled format={formatCurrency}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="Current Utilization" name="currentUtilization" required={required.currentUtilization} disabled
            sx={initialValues.requestId == 0 ? { display: { xs: 'none' } } : {}}
            value={initialValues.currentUtilization === null ? "N/A" : initialValues.currentUtilization} format={formatDecimal}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Loan Outstanding As Of" name="loanOutstandingAsOf" disabled {...dateFnsUtilsProps} />
        </LocalizationProvider>,
        <Field label="Avg Bank Shr Ledger Balance MTD" name="avgBankShrLedgerBalanceMtd" disabled format={formatCurrency}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field label="Avg Bank Shr Ledger Balance YTD" name="avgBankShrLedgerBalanceYtd" disabled format={formatCurrency}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,

        //index=31
        <Field label="LC Sublimit" name="lcsublimit" required={required.lcsublimit} {...helperTextProps}
            formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,

        <Field label="Swingline Sublimit" name="swinglineSublimit" required={required.swinglineSublimit}
            {...helperTextProps} {...disabledTextProps} formatOnBlur format={formatCurrency}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <TextField multiline label="Security Details" name="securityDetails" rows={4} required={required.securityDetails}
            {...helperTextProps} {...disabledTextProps} inputProps={{ maxLength: 255 }} />,
        <Field label="Advance Rate (& or Tiered)" name="advanceRatePercentage" required={required.advanceRatePercentage}
            {...helperTextProps} {...disabledTextProps} formatOnBlur format={formatDecimal}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,

        //index=35
        <TextField multiline label="Notes" name="notes" rows={5} {...disabledTextProps} inputProps={{ maxLength: 1000 }} />
    ];

    return (
        <Styles>
            <Form
                onSubmit={onSubmit}
                initialValues={submittedValues ? submittedValues : initialValues}
                subscription={subscription}
                validate={validate}
                key={subscription as any}
                decorators={[focusOnError]}
                render={({ handleSubmit, submitting, pristine, form }) => (
                    <form onSubmit={handleSubmit} noValidate={true}>
                        <Stack direction="row" justifyContent="flex-start" spacing={2} sx={{ py: 1 }}>
                            <Button type="button" variant="outlined" size="medium" onClick={onBack} startIcon={<ArrowBackOutlinedIcon />}>
                                Back
                            </Button>
                        </Stack>
                        <Grid container>
                            <Grid item xs={4}>
                                <fieldset className="wrapper-fieldset">
                                    <legend className="header">Borrower Profile</legend>
                                    {formFields.slice(0, 7).map((field, index) => (
                                        <Grid item key={index} p={1}>
                                            {field}
                                        </Grid>
                                    ))}
                                </fieldset>
                            </Grid>
                            <Grid item xs={4}>
                                <fieldset className="wrapper-fieldset" style={{ margin: '10px 20px' }}>
                                    <legend className="header">Terms & Conditions</legend>
                                    {formFields.slice(7, 15).map((field, index) => (
                                        <Grid item key={index} p={1}>
                                            {field}
                                        </Grid>
                                    ))}
                                </fieldset>
                            </Grid>
                            <Grid item xs={4}>
                                <fieldset className="wrapper-fieldset">
                                    <legend className="header">Cost Center Details</legend>
                                    {formFields.slice(15, 19).map((field, index) => (
                                        <Grid item key={index} p={1}>
                                            {field}
                                        </Grid>
                                    ))}
                                </fieldset>
                            </Grid>
                            <Grid item xs={12}>
                                <fieldset className="wrapper-fieldset">
                                    <legend className="header">Product & Facility Details</legend>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {
                                                formFields.slice(19, 25).map((field, index) => (
                                                    <Grid item key={index} p={1}>
                                                        {field}
                                                    </Grid>
                                                ))}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {
                                                formFields.slice(25, 31).map((field, index) => (
                                                    <Grid item key={index} p={1}>
                                                        {field}
                                                    </Grid>
                                                ))}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {
                                                formFields.slice(31, 35).map((field, index) => (
                                                    <Grid item key={index} p={1}>
                                                        {field}
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>
                            <Grid item xs={12}>
                                <fieldset className="wrapper-fieldset">
                                    <legend className="header">Notes</legend>
                                    {
                                        formFields.slice(35, 36).map((field, index) => (
                                            <Grid item key={index} p={1}>
                                                {field}
                                            </Grid>
                                        ))}
                                </fieldset>
                            </Grid>
                        </Grid>
                        {
                            !initialValues.isReadonly &&
                            (
                                <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ py: 1 }}>
                                    <Button type="button" variant="contained" size="medium" color="secondary" onClick={onReset(form)} disabled={submitting || pristine}>
                                        Reset
                                    </Button>
                                    <Button type="submit" variant="contained" size="medium" color="primary" disabled={submitting || pristine} >
                                        Submit
                                    </Button>
                                </Stack>)
                        }
                        < FormSpy
                            subscription={subscription}
                            onChange={props => {
                                if (props.submitting)
                                    setShowDialog(false);
                                else
                                    setShowDialog(!props.pristine);
                            }}
                        />
                    </form>
                )
                }
            />
        </Styles>
    );
};

export default MainForm;