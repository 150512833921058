import * as Yup from 'yup';

export const GeneralInformationSchema = {
    clientNameId: Yup.string().required("'Client Name' is a required field").nullable(),
    clientRelationshipStartDate: Yup.date().required("'Client Relationship Start Date' is a required field").typeError('Date must be in MM/DD/YYYY format').nullable(),
    depositRequirement: Yup.string().required("'Deposit Requirement' is a required field").nullable(),
    portfolioManagerEmail: Yup.string().required("'Portfolio Manager' is a required field").nullable(),
    portfolioAnalystEmail: Yup.string().required("'Portfolio Analyst' is a required field").nullable(),
    borrowerTypeId: Yup.string().required("'Borrower Type' is a required field").nullable(),
    productTypeId: Yup.string().required("'Product Type' is a required field").nullable(),
    totalCurrentFacilityAmount: Yup.string().required("'Total Current Facility Amount' is a required field").nullable(),
    lcsublimit: Yup.string().required("'LC Sublimit' is a required field").nullable(),
    swinglineSublimit: Yup.string().required("'Swingline Sublimit' is a required field").nullable(),
    securityDetails: Yup.string().required("'Security Details' is a required field").nullable(),
    advanceRatePercentage: Yup.string().required("'Advance Rate Percentage' is a required field").nullable(),
};
