import type { AuthState } from "../store/authSlice";

export const CanViewPage = (routeName: string, isPrivate: boolean | undefined, userInfo: AuthState) => {
    if (!isPrivate)
        return true;
    if (routeName == "Admin") {
        return userInfo.isAdmin;
    }
    if (routeName == "Reassignment") {
        return userInfo.isRequestor;
    }
    if (routeName == "Bulk Edit") {
        return userInfo.isRequestor;
    }
    return userInfo.isReadOnly;
}