import { SummaryApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
export type SummaryPageModel = Readonly<{
    id: number,
    requestId: number;
    cisnumber: string;
    loanNumber: string;
    borrowerName: string;
    clientName: string;
    borrowerType: string;
    dealType: string;
    productType: string;
    relationshipManagerName: string;
    portfolioManagerName: string;
    portfolioAnalystName: string;
    currentBankCommitment: number | null;
    currentOutstanding: number | null;
    avgBankShrLedgerBalanceYtd: number | null;
    securityDetails: string;
    baseRate: string;
    interestRate: number | null;
    spread: number | null;
}>;

export type SummaryPageState = Readonly<{
    isLoading: boolean;
    requests: SummaryPageModel[];
}>;

export type ReceiveRequestsPayload = Pick<SummaryPageState, "requests">;

const initialState: SummaryPageState = {
    requests: [],
    isLoading: false
};

export const summarySlice = createSlice({
    name: 'summary',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        receiveRequests: (state, action: PayloadAction<ReceiveRequestsPayload>) => {
            const { requests } = action.payload;
            state.isLoading = false;
            state.requests = requests;
        }
    }
});

export const getRequestsAsync = createAsyncThunk(
    'summary/getRequestsAsync',
    async (_, { dispatch }) => {

        // Dispatch request to intialize loading phase
        dispatch(startLoading());

        // Build http request and success handler in Promise<void> wrapper / complete processing
        try {
            const requests = await SummaryApi.getRequestsAsync();
            dispatch(stopLoading());
            const payload = { requests };
            dispatch(receiveRequests(payload));
        } catch (e) {
            dispatch(stopLoading());
            console.error(e);
        }
    }
);

export const { startLoading, stopLoading, receiveRequests } = summarySlice.actions;

export default summarySlice.reducer;