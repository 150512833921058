import styled, { css } from 'styled-components'
export default styled.div`
  fieldset.wrapper-fieldset {
    margin: 10px 0;
    border: 1px solid #005daa;
    padding: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    & > legend.header {
        background-color: #005daa;
        color: #fff;
        padding: 5px 15px;
        font-weight: bold;
        box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
        border-radius: 5px;
    }
  }
`
