import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { PrivateEquityApi } from '../api';
import type GeneralInformationModel from '../containers/Request/GeneralInformation/GeneralInformationModel';
import type PricingAndFeeModel from '../containers/Request/PricingAndFee/PricingAndFeeModel';
import type RelationAndPerformaceModel from '../containers/Request/RelationAndPerformace/RelationAndPerformaceModel';
import { FormatGeneralInformationModel, FormatPricingAndFeeModel, FormatRelationAndPerformaceModel } from '../utils/format';

export type PrivateEquityRequestState = Readonly<{
    isLoading: boolean,
    requestId: number,
    loanNumber: string,
    generalInformation: GeneralInformationModel,
    pricingAndFee: PricingAndFeeModel,
    relationAndPerformace: RelationAndPerformaceModel,
}>;

export type PrivateEquityRequestPayload = Pick<PrivateEquityRequestState, "requestId" | "loanNumber">;

const initialState: PrivateEquityRequestState = {
    isLoading: false,
    requestId: 0,
    loanNumber: '',
    generalInformation: {
        requestId: 0,
        loanNumber: '',
        borrowerName: '',
        cisnumber: '',
        clientNameId: '',
        clientName: '',
        clientRelationshipStartDate: undefined,
        terminationDate: '',
        currentRiskRateCode: '',
        statusId: '',
        status: '',
        term: null,
        committed: '',
        revolvingOrTerm: '',
        originalDateBooked: undefined,
        extensionDate: undefined,
        maturityDate: undefined,
        depositRequirement: '',
        unit: null,
        relationshipManagerEmail: '',
        relationshipManagerName: '',
        portfolioManagerEmail: '',
        portfolioManagerName: '',
        portfolioAnalystEmail: '',
        portfolioAnalystName: '',
        borrowerTypeId: '',
        borrowerType: '',
        productTypeId: '',
        productType: '',
        dealType: '',
        agentBank: '',
        currentBankCommitment: '',
        totalCurrentFacilityAmount: null,
        ewbshare: '',
        currentOutstanding: '',
        currentUtilization: '',
        loanOutstandingAsOf: undefined,
        avgBankShrLedgerBalanceMtd: '',
        avgBankShrLedgerBalanceYtd: '',
        lcsublimit: '',
        swinglineSublimit: '',
        security: '',
        securityDetails: '',
        advanceRatePercentage: '',
        notes: '',
        isReadonly: false,
        isPaidOff: false
    },
    pricingAndFee: {
        requestId: 0,
        loanNumber: '',
        borrowerName: '',
        baseRate: '',
        interestRate: '',
        spread: '',
        floorRate: '',
        upfrontFee: '',
        unusedFee: '',
        tieredUnused: '',
        advanceFee: '',
        extensionFee: '',
        lcfee: '',
        syndicationFee: '',
        anniversaryFee: '',
        isReadonly: false
    },
    relationAndPerformace: {
        requestId: 0,
        loanNumber: '',
        borrowerName: '',
        medepositAmount: '',
        avgDepositBalanceMtd: '',
        avgDepositBalanceYtd: '',
        fxfee: '',
        gtsfee: '',
        intRateDerivativeFee: '',
        monthlyRevenue: '',
        ltdrevenue: '',
        initialRoa: '',
        initialIrr: '',
        isReadonly: false
    }
};

export const privateEquityRequestSlice = createSlice({
    name: 'privateEquityRequest',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        requestPrivateEquityData: (state, action: PayloadAction<PrivateEquityRequestPayload>) => {
            state.isLoading = true;
            state.requestId = action.payload.requestId;
            state.loanNumber = action.payload.loanNumber;
        },
        receiveGeneralInformation: (state, action: PayloadAction<GeneralInformationModel>) => {
            state.isLoading = false;
            state.requestId = action.payload.requestId;
            state.generalInformation = FormatGeneralInformationModel(action.payload);
        },
        receivePricingAndFees: (state, action: PayloadAction<PricingAndFeeModel>) => {
            state.isLoading = false;
            state.pricingAndFee = FormatPricingAndFeeModel(action.payload);
            state.requestId = action.payload.requestId;
        },
        receiveRelationAndPerformace: (state, action: PayloadAction<RelationAndPerformaceModel>) => {
            state.isLoading = false;
            state.relationAndPerformace = FormatRelationAndPerformaceModel(action.payload);
            state.requestId = action.payload.requestId;
        }
    },
});

export const getGeneralInformationAsync = createAsyncThunk(
    'privateEquityRequest/getGeneralInformationAsync',
    async (payload: PrivateEquityRequestPayload, { dispatch }) => {

        // Dispatch request to intialize loading phase
        dispatch(requestPrivateEquityData(payload));

        // Build http request and success handler in Promise<void> wrapper / complete processing
        try {
            const generalInformation = await PrivateEquityApi.getGeneralInformationAsync(payload);
            dispatch(receiveGeneralInformation(generalInformation));
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            return e.response.status;
        }
    }
);

export const saveGeneralInformationAsync = createAsyncThunk(
    'privateEquityRequest/saveGeneralInformationAsync',
    async (generalInformation: GeneralInformationModel, { dispatch }) => {
        try {
            dispatch(startLoading());
            const newRequestId = await PrivateEquityApi.saveGeneralInformationAsync(generalInformation);
            var payload = {
                requestId: newRequestId,
                loanNumber: ''
            };
            await dispatch(getGeneralInformationAsync(payload));
            return newRequestId;
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.message + (typeof (e.response?.data) === 'string' ? ": " + e.response?.data : "");
            }
            return e.response.statusText;
        }
    }
);

export const getPricingAndFeesAsync = createAsyncThunk(
    'privateEquityRequest/getPricingAndFeesAsync',
    async (payload: PrivateEquityRequestPayload, { dispatch }) => {

        // Dispatch request to intialize loading phase
        dispatch(requestPrivateEquityData(payload));

        // Build http request and success handler in Promise<void> wrapper / complete processing
        try {
            const pricingAndFees = await PrivateEquityApi.getPricingAndFeesAsync(payload);
            dispatch(receivePricingAndFees(pricingAndFees));
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            return e.response.status;
        }
    }
);

export const savePricingAndFeesAsync = createAsyncThunk(
    'admin/savePricingAndFeesAsync',
    async (pricingAndFees: PricingAndFeeModel, { dispatch }) => {
        try {
            dispatch(startLoading());
            const newRequestId = await PrivateEquityApi.savePricingAndFeesAsync(pricingAndFees);
            var payload = {
                requestId: newRequestId,
                loanNumber: ''
            };
            dispatch(getPricingAndFeesAsync(payload));
            return newRequestId;
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.message + (typeof (e.response?.data) === 'string' ? ": " + e.response?.data : "");
            }
            return e.response.statusText;
        }
    }
);

export const getRelationAndPerformaceAsync = createAsyncThunk(
    'privateEquityRequest/getRelationAndPerformaceAsync',
    async (payload: PrivateEquityRequestPayload, { dispatch }) => {

        // Dispatch request to intialize loading phase
        dispatch(requestPrivateEquityData(payload));

        // Build http request and success handler in Promise<void> wrapper / complete processing
        try {
            const relationAndPerformace = await PrivateEquityApi.getRelationAndPerformaceAsync(payload);
            dispatch(receiveRelationAndPerformace(relationAndPerformace));
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            return e.response.status;
        }
    }
);

export const saveRelationAndPerformaceAsync = createAsyncThunk(
    'admin/saveRelationAndPerformaceAsync',
    async (relationAndPerformace: RelationAndPerformaceModel, { dispatch }) => {
        try {
            dispatch(startLoading());
            const newRequestId = await PrivateEquityApi.saveRelationAndPerformaceAsync(relationAndPerformace);
            var payload = {
                requestId: newRequestId,
                loanNumber: ''
            };
            dispatch(getRelationAndPerformaceAsync(payload));
            return newRequestId;
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.message + (typeof (e.response?.data) === 'string' ? ": " + e.response?.data : "");
            }
            return e.response.statusText;
        }
    }
);

export const { startLoading, stopLoading, requestPrivateEquityData, receiveGeneralInformation, receivePricingAndFees, receiveRelationAndPerformace } = privateEquityRequestSlice.actions;

export default privateEquityRequestSlice.reducer;

