import * as React from 'react';
import { type FunctionComponent } from 'react';
import { Spinner } from '../../components';
import { useAppDispatch, useAppSelector } from '../../store';
import { getLookupsAsync, getUserLookupsAsync, LookupTypeEnum, type LookupDataState } from '../../store/adminSlice';
import { getRequestsAsync, type BulkEditPageModel } from '../../store/bulkEditSlice';
import BulkEditGrid from './BulkEditGrid';

const BulkEditPage: FunctionComponent = () => {
    const requests = useAppSelector<BulkEditPageModel[]>((state) => state.bulkEdit.requests);
    const lookup = useAppSelector<LookupDataState>((state) => state.lookup);
    const isLoading = useAppSelector<boolean>((state) => state.bulkEdit.isLoading);

    const dispatch = useAppDispatch();
    React.useEffect(() => {
        dispatch(getRequestsAsync());
        dispatch(getLookupsAsync(LookupTypeEnum.ClientName));
        dispatch(getLookupsAsync(LookupTypeEnum.BorrowerType));
        dispatch(getLookupsAsync(LookupTypeEnum.ProductType));
        dispatch(getLookupsAsync(LookupTypeEnum.Status));
        dispatch(getUserLookupsAsync(LookupTypeEnum.User));
    }, [dispatch]);

    return (
        <div className="section" >
            <div className="container is-fluid">
                <h3 className="title is-3">
                    Bulk Edit
                </h3>
                <div className="box container-box">
                    <Spinner isLoading={isLoading} />
                    <BulkEditGrid requests={requests} lookup={lookup} />
                </div>
            </div>
        </div>
    );
};

export default BulkEditPage;
