import * as React from 'react';
import { type FunctionComponent } from 'react';
import { Spinner } from '../../components';
import { useAppDispatch, useAppSelector } from '../../store';
import { getRequestsAsync, type SummaryPageModel } from '../../store/summarySlice';
import SummaryGrid from './SummaryGrid';

const SummaryPage: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(getRequestsAsync());
    }, [dispatch]);

    const requests = useAppSelector<SummaryPageModel[]>((state) => state.summary.requests);
    const isLoading = useAppSelector<boolean>((state) => state.summary.isLoading);
    return (
        <div className="section">
            <div className="container is-fluid">
                <h3 className="title is-3">
                    Private Equity Dashboard
                </h3>
                <div className="box container-box">
                    <Spinner isLoading={isLoading} />
                    <SummaryGrid requests={requests} />
                </div>
            </div>
        </div>
    );
};

export default SummaryPage;
