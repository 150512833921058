import numeral from "numeral";
import { format } from "date-fns";
import type GeneralInformationModel from "../containers/Request/GeneralInformation/GeneralInformationModel";
import type PricingAndFeeModel from "../containers/Request/PricingAndFee/PricingAndFeeModel";
import type RelationAndPerformaceModel from "../containers/Request/RelationAndPerformace/RelationAndPerformaceModel";

export const formatDecimal = (value: any) => {
    return value === undefined || value === null
        ? '' // make controlled
        : numeral(value).format("0.000");
}
export const formatCurrency = (value: any) => {
    return value === undefined || value === null
        ? '' // make controlled
        : numeral(value).format("0,0.00");
}
export const formatDateString = (value: any) => {
    if (value === undefined || value === null)
        return value;
    var date = new Date(value);
    return format(date, "MM/dd/yyyy");
}

export const FormatGeneralInformationModel = (payload: GeneralInformationModel) => {
    payload.advanceRatePercentage = formatDecimal(payload.advanceRatePercentage);
    payload.terminationDate = formatDateString(payload.terminationDate);
    payload.totalCurrentFacilityAmount = formatCurrency(payload.totalCurrentFacilityAmount);
    payload.lcsublimit = formatCurrency(payload.lcsublimit);
    payload.swinglineSublimit = formatCurrency(payload.swinglineSublimit);
    return payload;
}

export const FormatPricingAndFeeModel = (payload: PricingAndFeeModel) => {
    payload.interestRate = formatDecimal(payload.interestRate);
    payload.spread = formatDecimal(payload.spread);
    payload.floorRate = formatDecimal(payload.floorRate);
    payload.upfrontFee = formatDecimal(payload.upfrontFee);
    payload.unusedFee = formatDecimal(payload.unusedFee);
    payload.extensionFee = formatDecimal(payload.extensionFee);
    payload.lcfee = formatDecimal(payload.lcfee);
    payload.syndicationFee = formatDecimal(payload.syndicationFee);

    payload.tieredUnused = formatCurrency(payload.tieredUnused);
    payload.advanceFee = formatCurrency(payload.advanceFee);
    payload.anniversaryFee = formatCurrency(payload.anniversaryFee);

    return payload;
}

export const FormatRelationAndPerformaceModel = (payload: RelationAndPerformaceModel) => {
    payload.initialRoa = formatDecimal(payload.initialRoa);
    payload.initialIrr = formatDecimal(payload.initialIrr);

    payload.medepositAmount = formatCurrency(payload.medepositAmount);
    payload.avgDepositBalanceMtd = formatCurrency(payload.avgDepositBalanceMtd);
    payload.avgDepositBalanceYtd = formatCurrency(payload.avgDepositBalanceYtd);
    payload.fxfee = formatCurrency(payload.fxfee);
    payload.gtsfee = formatCurrency(payload.gtsfee);
    payload.intRateDerivativeFee = formatCurrency(payload.intRateDerivativeFee);
    payload.monthlyRevenue = formatCurrency(payload.monthlyRevenue);
    payload.ltdrevenue = formatCurrency(payload.ltdrevenue);

    return payload;
}