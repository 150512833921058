import * as React from 'react';
import * as Yup from 'yup';
import { Button, createFilterOptions, Grid, Stack } from '@mui/material';
import type { LookupDataState, UserLookupData } from '../../store/adminSlice';
import { Autocomplete, makeValidate } from 'mui-rff';
import Styles from '../Request/Styles';
import { Form } from 'react-final-form';

export interface ReassignmentFormModel {
    portfolioManagerEmail: string,
    portfolioManagerName: string,
    portfolioAnalystEmail: string,
    portfolioAnalystName: string,
};
export const ReassignmentSchema = {
    portfolioManagerEmail: Yup.string().nullable(),
    portfolioAnalystEmail: Yup.string().nullable()
};

const schema = Yup.object().shape(ReassignmentSchema);
const validate = makeValidate(schema, (err) => {
    const { message } = err;
    return message;
});

const filterOptions = createFilterOptions({
    matchFrom: 'start'
});

interface MainFormProps {
    pristine: boolean,
    lookup: LookupDataState,
    onSubmit: any,
    portfolioManagerEmail: any,
    handlePortfolioManagerEmailChange: any,
    portfolioAnalystEmail: any,
    handlePortfolioAnalystEmailChange: any
}
const ReassignmentForm: React.FunctionComponent<MainFormProps> = ({ pristine, lookup, onSubmit,
    portfolioManagerEmail, handlePortfolioManagerEmailChange, portfolioAnalystEmail, handlePortfolioAnalystEmailChange }) => {
    const userLookups: UserLookupData[] = lookup.UserLookup;
    const initialValues: ReassignmentFormModel = {
        portfolioManagerName: '',
        portfolioAnalystName: '',
        portfolioManagerEmail: '',
        portfolioAnalystEmail: ''
    };

    const formFields = [
        //index=0
        <Autocomplete
            label="Portfolio Manager"
            name="portfolioManagerEmail"
            options={userLookups.map(input => ({ label: input.value, value: input.key }))}
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => option?.label || ""}
            value={portfolioManagerEmail || null}
            onChange={handlePortfolioManagerEmailChange}
            defaultValue=""
            fieldProps={{ filterOptions: filterOptions }}
        />,
        <Autocomplete
            label="Portfolio Analyst"
            name="portfolioAnalystEmail"
            options={userLookups.map(input => ({ label: input.value, value: input.key }))}
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => option?.label || ""}
            value={portfolioAnalystEmail || null}
            onChange={handlePortfolioAnalystEmailChange}
            defaultValue=""
            fieldProps={{ filterOptions: filterOptions }}
        />
    ];

    return (
        <Styles className="container">
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
                key={pristine as any}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate={true}>
                        <Grid container>
                            <Grid item xs={12}>
                                <fieldset className="wrapper-fieldset">
                                    <legend className="header">Cost Center Details</legend>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {
                                                formFields.slice(0, 1).map((field, index) => (
                                                    <Grid item key={index} p={1}>
                                                        {field}
                                                    </Grid>
                                                ))}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {
                                                formFields.slice(1, 2).map((field, index) => (
                                                    <Grid item key={index} p={1}>
                                                        {field}
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>
                        </Grid>
                        <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ py: 1 }}>
                            <Button type="submit" variant="contained" size="medium" color="primary" disabled={pristine}>
                                Save
                            </Button>
                        </Stack>
                    </form>
                )
                }
            />
        </Styles>
    );
};

export default ReassignmentForm;