import type GeneralInformationModel from '../containers/Request/GeneralInformation/GeneralInformationModel';
import type PricingAndFeeModel from '../containers/Request/PricingAndFee/PricingAndFeeModel';
import type RelationAndPerformaceModel from '../containers/Request/RelationAndPerformace/RelationAndPerformaceModel';
import type { PrivateEquityRequestPayload } from '../store/privateEquityRequestSlice';
import { BaseService } from './base.service';

/**
 * Admin API abstraction layer communication via Axios (typescript singleton pattern)
 */
class PrivateEquityService extends BaseService {
    private static _PrivateEquityService: PrivateEquityService;
    private static _controller: string = 'PrivateEquity';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): PrivateEquityService {
        return this._PrivateEquityService || (this._PrivateEquityService = new this(this._controller));
    }

    public async getGeneralInformationAsync(payload: PrivateEquityRequestPayload): Promise<GeneralInformationModel> {
        const url = `GetLoanGeneralInformation?requestId=${payload.requestId}&loanNumber=${payload.loanNumber}`;
        const { data } = await this.$http.get<GeneralInformationModel>(url);
        return data;
    }

    public async getPricingAndFeesAsync(payload: PrivateEquityRequestPayload): Promise<PricingAndFeeModel> {
        const url = `GetPricingAndFees?requestId=${payload.requestId}&loanNumber=${payload.loanNumber}`;
        const { data } = await this.$http.get<PricingAndFeeModel>(url);
        return data;
    }

    public async saveGeneralInformationAsync(loanGeneralInformation: GeneralInformationModel): Promise<number> {
        const { data } = await this.$http.post<number>('SaveLoanGeneralInformation', { loanGeneralInformation });
        return data;
    }

    public async savePricingAndFeesAsync(pricingAndFee: PricingAndFeeModel): Promise<number> {
        const { data } = await this.$http.post<number>('SavePricingAndFee', { pricingAndFee });
        return data;
    }
    public async getRelationAndPerformaceAsync(payload: PrivateEquityRequestPayload): Promise<RelationAndPerformaceModel> {
        const url = `GetRelationAndPerformace?requestId=${payload.requestId}&loanNumber=${payload.loanNumber}`;
        const { data } = await this.$http.get<RelationAndPerformaceModel>(url);
        return data;
    }

    public async saveRelationAndPerformaceAsync(relationAndPerformace: RelationAndPerformaceModel): Promise<number> {
        const { data } = await this.$http.post<number>('SaveRelationAndPerformace', { relationAndPerformace });
        return data;
    }
}

export const PrivateEquityApi = PrivateEquityService.Instance;
