import * as React from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes, DataGridPro,
    type GridRowModesModel,
    type GridColumns,
    type GridRowParams,
    type MuiEvent,
    GridActionsCellItem,
    type GridEventListener,
    type GridRowId,
    type GridRowModel
} from '@mui/x-data-grid-pro';
import {
    deleteLookupsAsync, getLookupsAsync, postLookupsAsync, putLookupsAsync,
    type LookupData,
    type LookupInfo,
    type LookupTypeEnum
} from '../../store/adminSlice';
import { useAppDispatch } from '../../store';
import { Alert, Snackbar, type AlertProps } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import LookupGridToolbar from './LookupGridToolbar';

type LookupTableProps = Pick<LookupInfo, "lookupType" | "lookups">;
const LookupGrid = React.memo<LookupTableProps>(({ lookupType, lookups }) => {
    const confirm = useConfirm();
    const dispatch = useAppDispatch();

    const [lookupTypeState, setlookupType] = React.useState<LookupTypeEnum>(lookupType);
    const [pageSize, setPageSize] = React.useState<number>(5);
    const [page, setPage] = React.useState<number>(0);
    const [rows, setRows] = React.useState<LookupData[]>(lookups);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const [snackbar, setSnackbar] = React.useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    React.useEffect(() => {
        setlookupType(lookupType);
        setRows(lookups);
    }, [lookupType, lookups]);

    const UpdateRow = React.useCallback(
        (newRow: GridRowModel) =>
            new Promise<Partial<LookupData>>(async (resolve, reject) => {
                if (newRow.value?.trim() === '') {
                    reject(new Error("Error while saving data: Value can't be empty."));
                } else {
                    const data = {
                        lookupType: lookupTypeState,
                        lookup: { key: newRow.key, value: newRow.value }
                    };
                    if (newRow.key == 0) { //add
                        const newLookup = await dispatch(postLookupsAsync(data));
                        if (newLookup.payload.key != null) {
                            setRows((oldRows) => ([...oldRows.filter((row) => row.key != 0), newLookup.payload]));
                            resolve(newLookup.payload);
                        }
                        else
                            reject(new Error("Error while saving data: " + newLookup.payload));
                    } else {//update
                        const newLookup = await dispatch(putLookupsAsync(data));
                        if (newRow.key == newLookup.payload.key)
                            resolve(newLookup.payload);
                        else
                            reject(new Error("Error while saving data: " + newLookup.payload));
                    }
                }
            }),
        [dispatch]
    );
    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel) => {
            const response = await UpdateRow(newRow);
            setSnackbar({ children: 'Record updated successfully.', severity: 'success' });
            await dispatch(getLookupsAsync(lookupTypeState));
            return response;
        },
        [UpdateRow]);

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const DeleteRow = React.useCallback(
        (key: number) =>
            new Promise<Partial<boolean>>(async (resolve, reject) => {
                var data = {
                    lookupType: lookupTypeState,
                    lookup: { key: key, value: '' }
                };
                var response = await dispatch(deleteLookupsAsync(data));
                if (response.payload === true) {
                    setRows(rows.filter((row) => row.key != key));
                    resolve(response.payload);
                }
                else
                    reject(new Error("Error while deleting record: " + response.payload));
            }),
        [dispatch],
    );

    const handleDeleteClick = (id: GridRowId) => async () => {
        if (id == 0) {
            handleCancelClick(id);
        } else {
            confirm({ description: `Are you sure to delete this record with Id:${id}?` })
                .then(async () => {
                    var response = await DeleteRow(parseInt(id.toString()));
                    if (response) {
                        setSnackbar({ children: 'Record deleted successfully.', severity: 'success' });
                        await dispatch(getLookupsAsync(lookupTypeState));
                    }
                })
                .catch(() => setSnackbar({ children: 'Deletion cancelled.', severity: 'success' }));
        }
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow = rows.find((row) => row.key == id);
        if (editedRow?.key == 0) {
            setRows(rows.filter((row) => row.key != id));
        }
    };

    const columns: GridColumns = [
        {
            field: 'key', headerName: 'Id', type: 'number', editable: false, minWidth: 100, hideable: false
        },
        {
            field: 'value', headerName: 'Value', editable: true, flex: 1, hideable: false
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            hideable: false,
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGridPro
                disableSelectionOnClick
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                pagination
                rows={rows}
                columns={columns}
                editMode="row"
                getRowId={(row: any) => row.key}
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                components={{
                    Toolbar: LookupGridToolbar,
                }}
                componentsProps={{
                    toolbar: { setRows, setRowModesModel, setPage, pageSize },
                }}
                experimentalFeatures={{ newEditingApi: true }}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Box>
    );
});

export default LookupGrid;