import * as React from 'react';
import { type FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from '../../components';
import { useAppDispatch, useAppSelector } from '../../store';
import { getUserLookupsAsync, LookupTypeEnum, type LookupDataState } from '../../store/adminSlice';
import { getRequestsAsync, saveReassignmentsAsync, type ConfirmationPageModel, type ReassignmentPageModel } from '../../store/reassignmentSlice';
import ConfirmationGrid from './ConfirmationGrid';
import ReassignmentForm from './ReassignmentForm';
import ReassignmentGrid from './ReassignmentGrid';

const ReassignmentPage: FunctionComponent = () => {
    const requests = useAppSelector<ReassignmentPageModel[]>((state) => state.reassignment.requests);
    const lookup = useAppSelector<LookupDataState>((state) => state.lookup);
    const isLoading = useAppSelector<boolean>((state) => state.reassignment.isLoading);

    const [rows, setRows] = React.useState(requests);
    const [selectionModel, setSelectionModel] = React.useState<[]>([]);
    const [selectedRows, setSelectedRows] = React.useState<ConfirmationPageModel[]>();
    const [open, setOpen] = React.useState(false);
    const [pristine, setpristine] = React.useState(true);

    const [portfolioManagerEmail, setPortfolioManagerEmail] = React.useState<any>();
    const handlePortfolioManagerEmailChange = (e: React.SyntheticEvent, v: React.SetStateAction<any>) => {
        setPortfolioManagerEmail(v);
        resetPristine((v == null && portfolioAnalystEmail == null) || selectionModel.length === 0);
    };
    const [portfolioAnalystEmail, setPortfolioAnalystEmail] = React.useState<any>();
    const handlePortfolioAnalystEmailChange = (e: React.SyntheticEvent, v: React.SetStateAction<any>) => {
        setPortfolioAnalystEmail(v);
        resetPristine((portfolioManagerEmail == null && v == null) || selectionModel.length === 0);
    };

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(getRequestsAsync());
        dispatch(getUserLookupsAsync(LookupTypeEnum.User));
    }, [dispatch]);

    React.useEffect(() => {
        setRows(requests);
    }, [requests])

    const handleClose = () => {
        setOpen(false);
    };
    const resetPristine = (disabled: boolean) => {
        setpristine(disabled);
    };
    const handleSave = () => {
        var selected = selectedRows?.map(input => ({
            ...input,
            portfolioManagerNameAfter: portfolioManagerEmail == null ? input.portfolioManagerName : portfolioManagerEmail.label,
            portfolioManagerEmailAfter: portfolioManagerEmail == null ? input.portfolioManagerEmail : portfolioManagerEmail.value,
            portfolioAnalystNameAfter: portfolioAnalystEmail == null ? input.portfolioAnalystName : portfolioAnalystEmail.label,
            portfolioAnalystEmailAfter: portfolioAnalystEmail == null ? input.portfolioAnalystEmail : portfolioAnalystEmail.value
        }));
        setSelectedRows(selected);
        setOpen(true);
    };
    const handleSubmit = async () => {
        if (selectedRows != undefined) {
            toast.dismiss();
            var response = await dispatch(saveReassignmentsAsync(selectedRows));
            if (response.payload === true) {
                setTimeout(() => toast.success("Reassignment request has been submitted successfully."), 100);
                dispatch(getRequestsAsync());
            } else {
                let errroMsg = "Unable to save Reassignment request. " + response.payload;
                setTimeout(() => toast.error(errroMsg, { autoClose: false }));
            }
            setOpen(false);
            setSelectedRows([]);
            setSelectionModel([]);
            setPortfolioManagerEmail(null);
            setPortfolioAnalystEmail(null);
            resetPristine(true);
        }
    };

    function onSelectionModelChange(ids: any) {
        setSelectionModel(ids);
        const selectedIDs = new Set(ids);
        const selected = rows.filter((row) =>
            selectedIDs.has(row.id),
        ).map(input => ({
            ...input,
            portfolioManagerNameAfter: '',
            portfolioManagerEmailAfter: '',
            portfolioAnalystNameAfter: '',
            portfolioAnalystEmailAfter: ''
        }));
        setSelectedRows(selected);
        setPortfolioManagerEmail(portfolioManagerEmail);
        resetPristine((portfolioManagerEmail == null && portfolioAnalystEmail == null) || ids.length === 0);
    }
    return (
        <div className="section" >
            <div className="container is-fluid">
                <h3 className="title is-3">
                    Reassignment
                </h3>
                <div className="box container-box">
                    <Spinner isLoading={isLoading} />
                    <ReassignmentGrid
                        rows={rows}
                        onSelectionModelChange={onSelectionModelChange}
                        selectionModel={selectionModel} />
                    <ReassignmentForm pristine={pristine}
                        lookup={lookup}
                        onSubmit={handleSave}
                        portfolioManagerEmail={portfolioManagerEmail}
                        handlePortfolioManagerEmailChange={handlePortfolioManagerEmailChange}
                        portfolioAnalystEmail={portfolioAnalystEmail}
                        handlePortfolioAnalystEmailChange={handlePortfolioAnalystEmailChange} />
                    <ConfirmationGrid
                        rows={selectedRows ?? []}
                        open={open}
                        handleClose={handleClose}
                        handleSubmit={handleSubmit} />
                </div>
            </div>
        </div>
    );
};

export default ReassignmentPage;
