import { Autocomplete, TextField } from "@mui/material";
import { useGridApiContext, type GridColumns, type GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import { useCallback } from "react";
import type { LookupDataState } from "../../store/adminSlice";
import { customStringOperators, customNumericOperators, customDateOperators, customObjectStringOperators } from "../../utils/gridOperators";

interface BulkEditGridColumnsProps {
    lookup: LookupDataState,
    getActions: any
};

var customObjectStringOperator = customObjectStringOperators();
var customStringOperator = customStringOperators();
var customNumericOperator = customNumericOperators(false);
var customDateOperator = customDateOperators();
export const BulkEditGridColumns = (props: BulkEditGridColumnsProps): GridColumns => {
    const { lookup, getActions } = props;
    return ([
        {
            field: "requestId",
            headerName: "RequestId",
            type: "number",
            editable: false,
            "hide": true
        },
        {
            field: "loanNumber",
            headerName: "Loan #",
            width: 120,
            editable: false,
            hideable: false,
            filterOperators: customStringOperator
        },
        {
            field: "borrowerName",
            headerName: "Borrower",
            minWidth: 180,
            flex: 1,
            editable: false,
            filterOperators: customStringOperator
        },
        {
            field: "clientName",
            headerName: "Client Name",
            width: 180,
            renderEditCell: (params) => (
                <AutocompleteEditInputCell
                    params={params}
                    options={lookup.ClientNameLookup.lookups.map(input => ({ label: input.value, value: input.key.toString() }))}
                    freeSolo={false}
                    multiple={false}
                />
            ),
            renderCell: (params) => (
                <div className="MuiDataGrid-cellContent">{params.value?.label}</div>
            ),
            editable: true,
            filterOperators: customObjectStringOperator
        },
        {
            field: "clientRelationshipStartDate",
            headerName: "Client Relationship Start Date",
            width: 140,
            type: "date",
            valueGetter: ({ value }) => value && new Date(value),
            editable: true,
            filterOperators: customDateOperator
        },
        {
            field: "borrowerType",
            headerName: "Borrower Type",
            width: 180,
            renderEditCell: (params) => (
                <AutocompleteEditInputCell
                    params={params}
                    options={lookup.BorrowerTypeLookup.lookups.map(input => ({ label: input.value, value: input.key.toString() }))}
                    freeSolo={false}
                    multiple={false}
                />
            ),
            renderCell: (params) => (
                <div className="MuiDataGrid-cellContent">{params.value?.label}</div>
            ),
            editable: true,
            filterOperators: customObjectStringOperator
        },
        {
            field: "productType",
            headerName: "Product Type",
            width: 180,
            renderEditCell: (params) => (
                <AutocompleteEditInputCell
                    params={params}
                    options={lookup.ProductTypeLookup.lookups.map(input => ({ label: input.value, value: input.key.toString() }))}
                    freeSolo={false}
                    multiple={false}
                />
            ),
            renderCell: (params) => (
                <div className="MuiDataGrid-cellContent">{params.value?.label}</div>
            ),
            editable: true,
            filterOperators: customObjectStringOperator
        },
        {
            field: "relationshipManagerName",
            headerName: "Relationship Manager",
            width: 150,
            editable: false,
            filterOperators: customStringOperator
        },
        {
            field: "portfolioManager",
            headerName: "Portfolio Manager",
            width: 150,
            renderEditCell: (params) => (
                <AutocompleteEditInputCell
                    params={params}
                    options={lookup.UserLookup.map(input => ({ label: input.value, value: input.key }))}
                    freeSolo={false}
                    multiple={false}
                />
            ),
            renderCell: (params) => (
                <div className="MuiDataGrid-cellContent">{params.value?.label}</div>
            ),
            editable: true,
            filterOperators: customObjectStringOperator
        },
        {
            field: "portfolioAnalyst",
            headerName: "Portfolio Analyst",
            width: 150,
            renderEditCell: (params) => (
                <AutocompleteEditInputCell
                    params={params}
                    options={lookup.UserLookup.map(input => ({ label: input.value, value: input.key }))}
                    freeSolo={false}
                    multiple={false}
                />
            ),
            renderCell: (params) => (
                <div className="MuiDataGrid-cellContent">{params.value?.label}</div>
            ),
            editable: true,
            filterOperators: customObjectStringOperator
        },
        {
            field: "unit",
            headerName: "Unit",
            type: 'number',
            editable: false,
            filterOperators: customNumericOperator
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: getActions,
            hideable: false
        }
    ]);
};
interface AutocompleteEditInputCellProps {
    params: GridRenderEditCellParams,
    options: any[] | undefined,
    freeSolo?: boolean,
    multiple?: boolean,
    getOptionLabel?: (option: any) => string
}
export function AutocompleteEditInputCell(props: AutocompleteEditInputCellProps) {
    const { params, options, freeSolo, getOptionLabel, multiple } = props;
    const apiRef = useGridApiContext();
    const handleChange = useCallback((event: React.SyntheticEvent<Element, Event>, newValue: any) => {
        event.stopPropagation();
        apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: newValue });
    }, [params.id, params.field]);

    const getValue = useCallback(() => {
        if (params.value)
            return params.value;

        if (multiple)
            return [];

        return null;
    }, [params.value, multiple]);
    return (
        <Autocomplete
            value={getValue()}
            onChange={handleChange}
            onInputChange={(event, value) => (freeSolo && !multiple && event) && handleChange(event, value)}
            fullWidth
            size='small'
            disableClearable
            multiple={multiple}
            options={options ?? []}
            openOnFocus
            freeSolo={freeSolo}
            autoHighlight
            getOptionLabel={getOptionLabel}
            renderInput={(inputParams) => <TextField {...inputParams} error={params.error} />}
        />
    );
}