import * as React from 'react';
import { Stack, Button, styled } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import MuiAccordion, { type AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    type AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordianPanel from './AccordianPanel';
import { Spinner } from '../../components';
import { useAppSelector, useAppDispatch } from '../../store';
import { getLookupsAsync, type LookupData, LookupTypeEnum } from '../../store/adminSlice';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AdminPage: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const [Accordion1, setAccordion1] = React.useState<boolean>(false);
    const [Accordion2, setAccordion2] = React.useState<boolean>(false);
    const [Accordion3, setAccordion3] = React.useState<boolean>(false);

    //React.useEffect(() => {
    //    dispatch(getLookupsAsync(LookupTypeEnum.ClientName));
    //    dispatch(getLookupsAsync(LookupTypeEnum.BorrowerType));
    //    dispatch(getLookupsAsync(LookupTypeEnum.ProductType));
    //}, [dispatch]);

    const clientNameLookup = useAppSelector<LookupData[]>((state) => state.lookup.ClientNameLookup.lookups);
    const borrowerTypeLookup = useAppSelector<LookupData[]>((state) => state.lookup.BorrowerTypeLookup.lookups);
    const productTypeLookup = useAppSelector<LookupData[]>((state) => state.lookup.ProductTypeLookup.lookups);
    const isLoading = useAppSelector<boolean>((state) => state.lookup.isLoading);

    const onExpandCollapseAllClick = (isExpand: boolean) => {
        setAccordion1(isExpand);
        setAccordion2(isExpand);
        setAccordion3(isExpand);
        if (isExpand) {
            dispatch(getLookupsAsync(LookupTypeEnum.ClientName));
            dispatch(getLookupsAsync(LookupTypeEnum.BorrowerType));
            dispatch(getLookupsAsync(LookupTypeEnum.ProductType));
        }
    };
    const onAccordianChange = (expanded: boolean, lookuptype: LookupTypeEnum) => {
        if (expanded)
            dispatch(getLookupsAsync(lookuptype));
    };

    return (
        <div className="section">
            <div className="container is-fluid">
                <h3 className="title is-3">
                    Admin Section
                </h3>
                <div className="box container-box nopaddingTop">
                    <Spinner isLoading={isLoading} />
                    <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ py: 1 }}>
                        <Button variant="outlined" size="small" endIcon={<UnfoldMoreIcon />} onClick={() => onExpandCollapseAllClick(true)}>
                            Expand All
                        </Button>
                        <Button variant="outlined" size="small" endIcon={<UnfoldLessIcon />} onClick={() => onExpandCollapseAllClick(false)}>
                            Collapse All
                        </Button>
                    </Stack>
                    <Accordion expanded={Accordion1} TransitionProps={{ unmountOnExit: true }} onChange={(e, expanded) => onAccordianChange(expanded, LookupTypeEnum.ClientName)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" onClick={() => setAccordion1(prevState => !prevState)}>
                            <Typography>Manage Client Names</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AccordianPanel lookupType={LookupTypeEnum.ClientName} lookups={clientNameLookup} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={Accordion2} TransitionProps={{ unmountOnExit: true }} onChange={(e, expanded) => onAccordianChange(expanded, LookupTypeEnum.BorrowerType)}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" onClick={() => setAccordion2(prevState => !prevState)}>
                            <Typography>Manage Borrower Types</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AccordianPanel lookupType={LookupTypeEnum.BorrowerType} lookups={borrowerTypeLookup} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={Accordion3} TransitionProps={{ unmountOnExit: true }} onChange={(e, expanded) => onAccordianChange(expanded, LookupTypeEnum.ProductType)}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" onClick={() => setAccordion3(prevState => !prevState)}>
                            <Typography>Manage Product Types</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AccordianPanel lookupType={LookupTypeEnum.ProductType} lookups={productTypeLookup} />
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};
export default AdminPage;