import { Box, Button, Container, Grid, Typography } from "@mui/material";
import type { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

const Forbidden: FunctionComponent = () => {
    const navigate = useNavigate();
    var handleClick = () => {
        navigate("/", { replace: true });
    };
    return (
        <div className="section">
            <div className="container is-fluid">
                <h3 className="title is-3">
                    Private Equity Dashboard
                </h3>
                <div className="box container-box">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '50vh'
                        }}
                    >
                        <Container maxWidth="md">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h1">
                                        403
                                    </Typography>
                                    <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                                        Access denied.
                                    </Typography>
                                    <Button variant="contained" onClick={handleClick} sx={{ marginTop: '20px' }}>Back Home</Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
            </div>
        </div>
    );
};
export default Forbidden;
