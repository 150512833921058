import * as React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';

import { Button, Grid, Input, InputAdornment, Stack } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import * as Yup from 'yup';
import {
    makeValidate,
    TextField
} from 'mui-rff';

import Styles from '../Styles';
import { RelationAndPerformaceSchema } from './RelationAndPerformaceSchema';
import { type PrivateEquityRequestState } from '../../../store/privateEquityRequestSlice';
import type RelationAndPerformaceModel from './RelationAndPerformaceModel';
import { formatCurrency, formatDecimal } from '../../../utils';

const schema = Yup.object().shape(RelationAndPerformaceSchema);

const validate = makeValidate(schema, (err) => {
    const { message } = err;
    return message;
});

interface MainFormProps {
    subscription: any,
    privateEquityRequest: PrivateEquityRequestState,
    focusOnError: any,
    onSubmit: any,
    onReset: any,
    onBack: any,
    submittedValues: RelationAndPerformaceModel | undefined,
    setShowDialog: any
}

const MainForm: React.FunctionComponent<MainFormProps> = ({ subscription, privateEquityRequest, focusOnError, onSubmit, submittedValues, onReset, onBack, setShowDialog }) => {

    const initialValues: RelationAndPerformaceModel = privateEquityRequest.relationAndPerformace;
    const disabledTextProps = { disabled: initialValues.isReadonly };

    const formFields = [
        //index=0
        <TextField label="Loan Number" name="loanNumber" disabled />,
        <TextField label="Borrower Name" name="borrowerName" disabled />,

        //index=2
        <Field label="M/E Deposit Amount" name="medepositAmount" formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field name="avgDepositBalanceMtd" label="Avg Deposit Balance MTD" formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field name="avgDepositBalanceYtd" label="Avg Deposit Balance YTD" formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field name="fxfee" label="F/X Fees" formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field name="gtsfee" label="GTS Fees" formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field name="intRateDerivativeFee" label="Int Rate Derivative Fees" formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,

        //index=8
        <Field name="monthlyRevenue" label="Monthly Revenue" formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field name="ltdrevenue" label="LTD Revenue" formatOnBlur format={formatCurrency} {...disabledTextProps}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field name="initialRoa" label="Initial ROA" formatOnBlur format={formatDecimal} {...disabledTextProps}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>,
        <Field name="initialIrr" label="Initial IRR" formatOnBlur format={formatDecimal} {...disabledTextProps}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}>
            {props => (
                <TextField name={props.input.name} {...props} />
            )}
        </Field>
    ];

    return (
        <Styles>
            <Form
                onSubmit={onSubmit}
                initialValues={submittedValues ? submittedValues : initialValues}
                subscription={subscription}
                validate={validate}
                key={subscription as any}
                decorators={[focusOnError]}
                render={({ handleSubmit, submitting, pristine, form }) => (
                    <form onSubmit={handleSubmit} noValidate={true}>
                        <Stack direction="row" justifyContent="flex-start" spacing={2} sx={{ py: 1 }}>
                            <Button type="button" variant="outlined" size="medium" onClick={onBack} startIcon={<ArrowBackOutlinedIcon />}>
                                Back
                            </Button>
                        </Stack>
                        <Grid container>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="flex-start" spacing={1} py={1} my={2}>
                                    {
                                        formFields.slice(0, 1).map((field, index) => (
                                            <Grid item key={index} sx={{ mr: 1 }} xs={4}>
                                                {field}
                                            </Grid>
                                        ))
                                    }
                                    {
                                        formFields.slice(1, 2).map((field, index) => (
                                            <Grid item key={index} xs={8}>
                                                {field}
                                            </Grid>
                                        ))
                                    }
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <fieldset className="wrapper-fieldset" style={{ marginRight: '20px' }}>
                                    <legend className="header">Relationships</legend>
                                    {
                                        formFields.slice(2, 8).map((field, index) => (
                                            <Grid item key={index} p={1}>
                                                {field}
                                            </Grid>
                                        ))
                                    }
                                </fieldset>
                            </Grid>
                            <Grid item xs={6}>
                                <fieldset className="wrapper-fieldset">
                                    <legend className="header">Performance</legend>
                                    {
                                        formFields.slice(8, 12).map((field, index) => (
                                            <Grid item key={index} p={1}>
                                                {field}
                                            </Grid>
                                        ))
                                    }
                                </fieldset>
                            </Grid>
                        </Grid>
                        {
                            !initialValues.isReadonly &&
                            (
                                <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ py: 1 }}>
                                    <Button type="button" variant="contained" size="medium" color="secondary" onClick={onReset(form)} disabled={submitting || pristine}>
                                        Reset
                                    </Button>
                                    <Button type="submit" variant="contained" size="medium" color="primary" disabled={submitting || pristine} >
                                        Submit
                                    </Button>
                                </Stack>)
                        }
                        <FormSpy
                            subscription={subscription}
                            onChange={props => {
                                if (props.submitting)
                                    setShowDialog(false);
                                else
                                    setShowDialog(!props.pristine);
                            }}
                        />
                    </form>
                )}
            />
        </Styles>
    );
};

export default MainForm;