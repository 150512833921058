import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useNavigate, generatePath } from 'react-router-dom';
import { Routes } from '../../config';
import type { ReassignmentPageModel } from '../../store/reassignmentSlice';
import { ReassignmentGridColumns } from './GridColumns';
import ReassignmentGridToolbar from './ReassignmentGridToolbar';

interface ReassignmentGridProps {
    rows: ReassignmentPageModel[],
    onSelectionModelChange: any,
    selectionModel: any
}
const ReassignmentGrid = React.memo<ReassignmentGridProps>(({ rows, onSelectionModelChange, selectionModel }) => {

    const navigate = useNavigate();

    function handleOnClick(rowData: any) {
        const requestRoute = Routes.find((x) => x.name === 'Request')!;
        var requestPath = generatePath(requestRoute.path, { requestId: rowData.requestId });
        if (rowData.requestId == 0)
            requestPath = requestPath + "?loanNumber=" + rowData.loanNumber;
        navigate(requestPath);
    }
    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGridPro
                disableSelectionOnClick
                initialState={{
                    pagination: {
                        pageSize: 25,
                    },
                }}
                columns={ReassignmentGridColumns}
                rows={rows}
                getRowId={(row) => row.id}
                isCellEditable={() => false}
                components={{ Toolbar: ReassignmentGridToolbar }}
                onRowDoubleClick={(param) => handleOnClick(param.row)}
                disableColumnMenu={true}
                checkboxSelection={true}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectionModel} // add this line
            />
        </div>
    );
});

export default ReassignmentGrid;