import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { type TransitionProps } from '@mui/material/transitions';

import { DataGridPro } from '@mui/x-data-grid-pro';
import type { ConfirmationPageModel } from '../../store/reassignmentSlice';
import { ConfirmationGridColumns } from './GridColumns';
import { Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ConfirmationGridProps {
    rows: ConfirmationPageModel[],
    open: boolean,
    handleClose: any,
    handleSubmit: any
}
const ConfirmationGrid = React.memo<ConfirmationGridProps>(({ rows, open, handleClose, handleSubmit }) => {
    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Please confirm
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Toolbar>
                </AppBar>
                <div style={{ height: 500, width: '100%' }}>
                    <DataGridPro
                        disableSelectionOnClick
                        columns={ConfirmationGridColumns}
                        rows={rows}
                        getRowId={(row) => row.id}
                        isCellEditable={() => false}
                    />
                </div>
            </Dialog>
        </div >
    );
});

export default ConfirmationGrid;