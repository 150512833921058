import { BaseService } from './base.service';
import type { SummaryPageModel } from '../store/summarySlice';

/**
 * Summary API abstraction layer communication via Axios (typescript singleton pattern)
 */
class SummaryService extends BaseService {
    private static _summaryService: SummaryService;
    private static _controller: string = 'Summary';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): SummaryService {
        return this._summaryService || (this._summaryService = new this(this._controller));
    }

    public async getRequestsAsync(): Promise<SummaryPageModel[]> {
        const url = `GetSummaryPageRequests`;
        const { data } = await this.$http.get<SummaryPageModel[]>(url);
        return data;
    }
}

export const SummaryApi = SummaryService.Instance;
