import { BaseService } from './base.service';
import type { BulkEditPageModel } from '../store/bulkEditSlice';

/**
 * BulkEdit API abstraction layer communication via Axios (typescript singleton pattern)
 */
class BulkEditService extends BaseService {
    private static _bulkEditService: BulkEditService;
    private static _controller: string = 'BulkEdit';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): BulkEditService {
        return this._bulkEditService || (this._bulkEditService = new this(this._controller));
    }

    public async getRequestsAsync(): Promise<BulkEditPageModel[]> {
        const url = `GetBulkEditPageRequests`;
        const { data } = await this.$http.get<BulkEditPageModel[]>(url);
        return data;
    }

    public async saveBulkEditRequestAsync(bulkEditRequest: BulkEditPageModel): Promise<boolean> {
        const { data } = await this.$http.post<boolean>('SaveBulkEditRequest', { bulkEditRequest });
        return data;
    }
}

export const BulkEditApi = BulkEditService.Instance;
