import * as Yup from 'yup';

export const PricingAndFeeSchema = {
    upfrontFee: Yup.string().required("'Upfront Fee' is a required field").nullable(),
    unusedFee: Yup.string().required("'Unused Fee' is a required field").nullable(),
    advanceFee: Yup.string().required("'Advance Fee' is a required field").nullable(),
    extensionFee: Yup.string().required("'Extension Fee' is a required field").nullable(),
    lcfee: Yup.string().required("'LC Fee' is a required field").nullable(),
    syndicationFee: Yup.string().required("'Syndication Fee' is a required field").nullable(),
    anniversaryFee: Yup.string().required("'Anniversary Fee' is a required field").nullable()
};
