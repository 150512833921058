import { BulkEditApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

export type BulkEditPageModel = Readonly<{
    id: number,
    requestId: number;
    loanNumber: string;
    borrowerName: string;
    cisnumber: string;
    clientName: string;
    clientRelationshipStartDate: Date | undefined;
    borrowerType: string;
    productType: string;
    status: string;
    portfolioManager: string;
    portfolioAnalyst: string;
}>;

export type BulkEditPageState = Readonly<{
    isLoading: boolean;
    requests: BulkEditPageModel[];
}>;

export type ReceiveRequestsPayload = Pick<BulkEditPageState, "requests">;

const initialState: BulkEditPageState = {
    requests: [],
    isLoading: false
};

export const BulkEditSlice = createSlice({
    name: 'BulkEdit',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        receiveRequests: (state, action: PayloadAction<ReceiveRequestsPayload>) => {
            state.isLoading = false;
            const { requests } = action.payload;
            state.requests = requests;
        }
    }
});

export const getRequestsAsync = createAsyncThunk(
    'BulkEdit/getRequestsAsync',
    async (_, { dispatch }) => {

        // Dispatch request to intialize loading phase
        dispatch(startLoading());

        // Build http request and success handler in Promise<void> wrapper / complete processing
        try {
            const requests = await BulkEditApi.getRequestsAsync();
            //console.log(requests);
            dispatch(stopLoading());
            const payload = { requests };
            dispatch(receiveRequests(payload));
        } catch (e) {
            dispatch(stopLoading());
            console.error(e);
        }
    }
);

export const saveBulkEditRequestAsync = createAsyncThunk(
    'admin/saveBulkEditRequestAsync',
    async (bulkEditRequest: BulkEditPageModel, { dispatch }) => {
        try {
            dispatch(startLoading());
            const response = await BulkEditApi.saveBulkEditRequestAsync(bulkEditRequest);
            dispatch(getRequestsAsync());
            return response;
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.message + (typeof (e.response?.data) === 'string' ? ": " + e.response?.data : "");
            }
            return e.response.statusText;
        }
    }
);


export const { startLoading, stopLoading, receiveRequests } = BulkEditSlice.actions;

export default BulkEditSlice.reducer;